import React, { useEffect, useState } from 'react'
import {useAuth} from "../context/authcontext";
import {Link} from 'react-router-dom'
import {GetTaller} from './dbfirebase'
import { useDispatch, useSelector } from 'react-redux';
import {useGeneral} from '../context/generalContext'
import { DataSnapshot } from 'firebase/database';

export const ExpandNavBar = ({isOpen,toggleMenu}) => {
    


    return (
      <>
          <div className={`mobile-menu ${isOpen ? "active":""} `}  onClick={toggleMenu}>
  
  
          <div className='mobile-menu-container'>   
              <ul>
                 
                        <li className="menu-item">
                            <Link className="nav-link" to="/ordenes"style={{width:"10rem"}}>Ordenes</Link>
                        </li>
                         <li className="menu-item">
                            <Link className="nav-link" to="/precios"style={{width:"10rem"}}>Lista de Precios</Link>
                        </li>
                        <li className="menu-item">
                           <Link className="nav-link" to="/retails"style={{width:"10rem"}}>Lista de retails</Link>
                        </li>             
                        <li className="menu-item">
                            <Link className="nav-link" to="/remitos"style={{width:"10rem"}}>Remitos</Link>
                        </li>
                        <li className="menu-item">
                            <Link className="nav-link" to="/repuestos"style={{width:"10rem"}}>Repuestos</Link>
                        </li>
                        <li className='menu-item'>
                            <Link className='nav-link' to='/talleres'style={{width:"10rem"}}>Lista de Talleres</Link>
                        </li>
                        <li className="menu-item">
                          <Link className="nav-link" to="/ordenesTaller"style={{width:"10rem"}}>Seguimiento Taller</Link>
                        </li>     
              </ul>
          </div>
      </div>
  
      
      </>
    )
  }
  