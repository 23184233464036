import React, { useEffect, useState } from 'react'
import {ModalManual} from './ModalImagenesManual'
import '../css/manual.css'
import micuenta from '../images/mi-cuenta.PNG'
import ordenes from '../images/ordenesdeservicio.PNG'
import tabla from '../images/ordenes.PNG'
import buscador from '../images/buscador.PNG'
import filtrar from '../images/filtrar.PNG'
import buscadores from '../images/buscadores.PNG'
import taller from '../images/nuevotaller1.PNG'
import tabla1 from '../images/talleres.PNG'
import listata from '../images/buscarlistata.PNG'
import precios from '../images/precios2.PNG'
import margen from '../images/margen.PNG'
import tabla3 from '../images/lista-precios.PNG'
import consumidor from '../images/tabla-consumidor.PNG'
import final from '../images/consumidor-final.PNG'
import lista2 from '../images/buscador-lista-precio.PNG'
import retail from '../images/nuevo-retail.PNG'
import tabla4 from '../images/tabla-retails.PNG'
import busca from '../images/retail-buscador.PNG'
import trabajo from '../images/orden-trabajo.PNG'
import datos from '../images/datos-cliente.PNG'
import producto from '../images/datos-producto.PNG'
import credito from '../images/nota-credito.PNG'
import ordentaller from '../images/orden-taller.PNG'
import buscaordenes from '../images/retail-buscador.PNG'
import generar from '../images/generar-remito.PNG'
import remito from '../images/tabla-remitos.PNG'
import cerrar from '../images/cerrar-sesion.png'
import busqueda from '../images/remito.PNG'
import tabla5 from '../images/repuesto.PNG'

export const Manual = () => {

    const [imagen, setImagen] = useState()

    useEffect(() => {
        // Detecta el identificador de fragmento en la URL y desplázate al div correspondiente
        const fragmentIdentifier = window.location.hash;
        if (fragmentIdentifier) {
            const targetDiv = document.querySelector(fragmentIdentifier);
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        // contenedor padre, almacena todo el objeto html
        <div style={{ marginTop: "5rem" }} id='body'>
            {/* Modal que muestra las imagenes */}
            <ModalManual image={imagen}/>

            {/*Titulo de la seccion general*/}
            <h2>MANUAL DE USUARIO</h2>

            {/* SubTitulo de la seccion general */}
            <h4 style={{ marginBottom: "4rem" }}>Postventa-Service</h4>

            {/* Primer apartado, introduccion */}
            <div id="Introduccion">
                {/* parrafo, para hacer saltos de linea usamos <br/> */}
                <p>Con este sistema desarrollado por Goldmund - peabody podremos gestionar las órdenes de servicio de nuestros clientes, visualizar precios de repuestos y pedir repuestos.<br />
                    Además, observar en tiempo real el estado de nuestra compra o de la órden del cliente en servicio.</p>
            </div>
            <h2 style={{ textAling: 'center' }}>Indice</h2>
            
                <ul>
                    <li><a href='#a'>¿Dónde puedo ver las ordenes del día?</a></li>
                    <li><a href='#b'>¿Cómo hago para buscar el número de orden?</a></li>
                    <li><a href='#c'>¿Cómo ingreso un nuevo taller?</a></li>
                    <li><a href='#d'>¿Dónde puedo buscar un producto?</a></li>
                    <li><a href='#e'>¿Cómo puedo ingresar un nuevo retail?</a></li>
                    <li><a href='#f'>¿Dónde puedo ver las ordenes de taller?</a></li>
                    <li><a href='#g'>¿Dónde puedo cargar un remito?</a></li>
                </ul>

            {/* Segundo apartado, mi cuenta */}
            <div style={{ marginTop: "15rem" }} id="micuenta">

                {/* titulo */}
                <h4 style={{ marginBottom: "2rem" }}>MI CUENTA</h4>

                {/* parrafo */}
                <p>Una vez iniciada la sesión vera este apartado, donde podrá asegurarse de que se trata de su cuenta y de que sus datos son los correctos.</p>

                {/* contenedor con imagen descriptiva */}
                <div className='d-flex justify-content-center'>
                    <img src={micuenta} alt='micuenta' height='60%' width='60%' onClick={() => { setImagen(micuenta) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
                </div>
            </div>

            {/* Tercer apartado, ordenes de servicio */}
            <div style={{ marginTop: "15rem" }} id="ordenesDeServicio">

                {/* titulo */}
                <a id="a"></a><h4 style={{ marginBottom: "2rem" }}>ORDENES DE SERVICIO</h4>

                {/* parrafo */}

                <p> Esta sección nos va a mostrar las ordenes del día, las ordenes mayores a 3 días, y las ordenes pendientes mayores a 5 días.</p>

                <div>
                    <img  src={ordenes} alt='ordenes' height='80%' width='80%' onClick={() => { setImagen(ordenes) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                </div>

                <p>Más abajo de estos nos mostrará una tabla estan las diferentes órdenes que ya se han hecho y las que se vayan haciendo en el día.
                    La tabla tiene varias columnas con los siguientes datos:</p>
                    <ul>
                    <li>Fecha</li>
                    <li>N°de Orden</li>
                    <li>Taller</li>
                    <li>Cliente</li>
                    <li>CUIT/DNI</li>
                    <li>Categoría</li>
                    <li>Artículo</li>
                    <li>Estado</li>
                    <li>Costo Mdo</li>
                    <li>Garantía</li>
                    <li>N° Factura</li>
                    <li>Fecha de compra</li>
                    <li>Razón social</li>
                    <li>Leadtime</li>
                    <li>Descargar foto</li>
                    <li>Descargar factura</li>
                    </ul>
                

                <div className='d-flex justify-content-center'>
                    <img src={tabla} alt='tabla' height='80%' width='80%' onClick={() => { setImagen(tabla) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />

                </div>
                <br />
                <a id="b"></a>
                <p> Para poder obtener una busqueda mas efectiva, tendremos dos opciones. (Estas opciones siempre se veran arriba de las tablas) </p>
                <p>Por el Buscador, donde podremos buscar todo tipo de dato que requieras.</p>

                <div className='d-flex justify-content-left'>
                    <img src={buscador} alt='buscador' width='20%'/>
                </div>
                <p>En el lado derecho del buscador tendremos la opcion de <b>"Filtrar por estado"</b> donde si clickeamos podremos ver los diferentes estados de las ordenes.</p>

                <div className='d-flex justify-content-left'>
                    <img src={filtrar} alt='filtrar' height='12%' width='12%' onClick={() => { setImagen(filtrar) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
                </div>
                <br />
                <p>Y al lado de este tendremos la opción de descargar la tabla como planilla Excel, si es que así se lo necesite. </p>
                <p> Todo junto se ve asi. </p>

                <div className='d-flex justify-content-left'>
                    <img src={buscadores} alt='buscadores' width='90%'/>
                </div>
            </div>

            <div style={{ marginTop: "15rem" }} id="listaDeTalleres">

                <a id="c"></a>  <h4 style={{ marginBottom: "2rem" }}>LISTA DE TALLERES</h4>
            </div>

            <p>En esta sección a primera vista podemos ver la opción de ingresar un nuevo taller y una tabla con talleres ya ingresados anteriormente.</p>
            <p>Para poder ingresar un nuevo taller, tendremos que colocar todos nuestros datos segun nos vaya indicando cada casilla, le damos a guardar y ahí automaticamente apareceremos en la tabla.</p>
            <p><b>Cuando ingresamos nuestro CUIT/DNI y número de telefono, debemos ponerlo sin puntos ni guiones (Ejemplo: 11222333). </b></p>

            <div className='d-flex justify-content-center'>
                <img src={taller} alt='taller' height='90%' width='90%' onClick={() => { setImagen(taller) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <p>Luego de haber ingresado todos los datos correctamente, los podras obserar en la tabla.</p>

            <div className='d-flex justify-content-center'>
                <img src={tabla1} alt='tabla' height='80%' width='80%' onClick={() => { setImagen(tabla1) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <br />
            <p>En esta sección tambien tenemos la opción de buscar algún dato del taller, clickeando en la casilla <b>Buscar</b> y podremos descargar la tabla como una planilla Excel, clickeando en el icono del mismo.</p>

            <div className='d-flex justify-content-left'>
                <img src={listata} alt='lista' width='90%'/>
            </div>

            <div style={{ marginTop: "15rem" }} id="listaDePrecios">

                <a id="c"></a>  <h4 style={{ marginBottom: "2rem" }}>LISTA DE PRECIOS</h4>
            </div>
            <p>En esta sección podemos buscar un producto por categoría, tipo, artículo, descrpición.</p>
            <p>Tambien, en esta sección podremos hacer pedidos como consumidor final.</p>
            <p> Para poder hacer un pedido como consumidor final, debemos hacer click en donde dice <b>"crear nuevo pedido para consumidor final"</b> que se encuentra en la parte superior derecha de la página.</p>
            <p> Ya estando ahi, debemos llenar las casillas con la informacion que se nos pide. </p>
            <div className='d-flex justify-content-center'>
                <img src={final} alt='final' height='80%' width='80%' onClick={() => { setImagen(final) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <p> Cuando pongamos el articulo, nos hará una tabla con:</p>
            <ul>
            <li>Categoria</li>
            <li>Tipo</li>
            <li>Repuesto</li>
            <li>Descripción</li>
            <li>Precio Publico</li>
            <li>Precio taller</li>
            <li>Ultima Actualización</li>
            <li>Cantidad</li>
            </ul>
            <br />
            <p>Agregamos algunas observaciones del pedido, cargamos el perdido y lo confirmamos.</p>
            <div className='d-flex justify-content-center'>
                <img src={consumidor} alt='consumidor' heighr='70%' width='70%' onClick={() => { setImagen(consumidor) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <br />
            <p><a id='d'></a>Para poder buscar un producto debemos colocar la categoria y el tipo de producto que estemos buscando, y automaticamente se pondrá el articulo y la descripción del mismo.</p>
            <div className='d-flex justify-content-center'>
                <img src={precios} alt='precios' height='80%' width='80%' onClick={() => { setImagen(precios) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Al elegir un producto nos aparecerá la opción de actualizar el margen de los productos, eligiendo el número que se desee, le damos a <b>Guardar</b> y automaticamente actualizará los precios de la tabla.</p>
            <div className='d-flex justify-content-left'>
                <img src={margen} alt='margen' height='100%' width='100%' onClick={() => { setImagen(margen) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>La tabla se veria de esta forma.</p>

            <div className='d-flex justify-content-center'>
                <img src={tabla3} alt='tabla3' height='80%' width='80%' onClick={() => { setImagen(tabla3) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <br />
            <p>También tendrás la opción de buscar algún dato del producto en la casilla <b>Buscar</b> y de descargar el cuadro como planilla de Excel clickeando el icono del mismo. (Estas opciones siempre se veran arriba de las tablas.)</p>

            <div className='d-flex justify-content-left'>
                <img src={lista2} alt='lista' width='90%'/>
            </div>


            <div style={{ marginTop: "15rem" }} id="listaDeRetails"></div>

            <h4 style={{ marginBottom: "2rem" }}>LISTA DE RETAILS</h4>

            <p> En esta sección podremos ingresar un nuevo retail o ver retails ya ingresados anteriormente.</p>
            <a id="e"></a><p>Para poder ingresar un Retail, tendremos que colocar todos nuestros datos según nos vaya pidiendo cada casilla.</p>
            <p><b>Cuando ingresamos nuestros CUIT/DNI y numero de telefono, debemos ponerlo sin puntos ni guiones.(Ejemplo: 11222333)</b></p>

            <div className='d-flex justify-content-center'>
                <img src={retail} alt='retail' heigt='90%' width='90%' onClick={() => { setImagen(retail) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Ya habiendo ingresado todos los datos, le damos a guardar y los añadirá a la tabla. </p>

            <div className='d-flex justify-content-center'>
                <img src={tabla4} alt='tabla' height='80%' width='80%' onClick={() => { setImagen(tabla4) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Tambien tendras la opción de Buscar algun dato en la casilla <b>Buscar</b> y podremos descargar la tabla en una planilla Excel, clickeando en el icono del mismo.</p>
            <div className='d-flex justify-content-left'>
                <img src={busca} alt='busca' width='90%'/>
            </div>

            <div style={{ marginTop: "15rem" }} id="ordenDeTrabajo"></div>

            <h4 style={{ marginBottom: "2rem" }}>ORDEN DE TRABAJO</h4>
            <p>En esta sección a primera vista podemos observar Orden de trabajo, Datos del cliente y Datos del producto.</p>

            <p>En <b>Orden de trabajo</b> nos aparecerán todos nuestros datos.</p>

            <div className='d-flex justify-content-center'>
                <img src={trabajo} alt='trabajo' height='90%' width='90%' onClick={() => { setImagen(trabajo) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <p>Debajo de Orden de Trabajo, nos aparecerá <b>datos del cliente</b>, donde tendras que llenar las casillas con los datos detallados en el remito.</p>
            <p><b>Cuando ingresamos nuestro CUIT/DNI y número de teléfono, debemos ponerlo sin puntos ni guiones. (Ejemplo: 11222333)</b></p>

            <div className='d-flex justify-content-center'>
                <img src={datos} alt='datos' height='90%' width='90%' onClick={() => { setImagen(datos) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <br />
            <p>Y debajo de datos del cliente, tendremos <b>datos del producto</b> donde deberás llenar las casillas según el fallo de tu producto. </p>
            <br />
            <div className='d-flex justify-content-center'>
                <img src={producto} alt='producto' height='80%' width='80%' onClick={() => { setImagen(producto) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Ya al haberlo llenado todo, se vera igual que en la imagen de arriba , eligiendo la casilla R&D(reparar y devolver).</p>
            <p>Y eligiendo la casilla N/C (nota de credito) deberá mostrarte igual que en la imagen de abajo.</p>
            <br />

            <div className='d-flex justify-content-center'>
                <img src={credito} alt='credito' height='80%' width='80%' onClick={() => { setImagen(credito) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Le damos a guardar y ahi se nos guardara los datos en la tabla que aparece en la siguiente sección.</p>
            <p>Cuando lo guardemos nos dará la opción de imprimir los datos del producto y se nos generará un qr.</p>

            <div style={{marginTop: "15rem" }} id="seguimientoDeTaller"></div>
             
             <h4 style={{marginBottom: "2rem" }}>SEGUIMIENTO DE TALLER</h4>

            <a id="f"></a><p>En esta sección nos mostrará las ordenes de taller que hayamos hecho anteriormente.</p>

            <div className='d-flex justify-content-center'>
                <img src={ordentaller} alt='ordentaller' height='80%' width='80%' onClick={() => { setImagen(ordentaller) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <br />
            <p>Tambien nos mostrará una tabla con ordenes ya hechas anteriormente.</p>

            <p>Tambien tendremos la opción de buscar algún dato en la casilla <b>Buscar</b> y de descargar el cuadro como planilla de excel, clickeando el icono del mismo.</p>

            <div className='d-flex justify-content-left'>
                <img src={buscaordenes} alt='buscaordenes' width='90%'/>
            </div>

            <div style={{marginTop: "15rem" }} id="remitos"></div>

             <h4 style={{marginBottom: "2rem "}}>REMITOS</h4>
            
            <a id="g"></a><p>En esta seccion podremos registrar remitos, donde al igual que en las secciones anteriores, debemos ingresar todos nuestros datos.</p>
            <p><b>Cuando ingresamos nuestro CUIT/DNI y número de telefono, debemos ponerlo sin puntos ni guiones. (Ejemplo: 1222333)</b></p>

            <div className='d-flex justify-content-center'>
                <img src={generar} alt='generar' height='80%' width='80%' onClick={() => { setImagen(generar) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Ya habiendo completado todo, se deberia ver igual que en la imagen de abajo. Cargas tu número de remito y la cantidad de bultos, clickeamos la opción de <b>Guardar</b> y ahi te añade en la tabla que esta debajo. </p>
            <div className='d-flex justify-content-center'>
                <img src={remito} alt='remito' height='80%' width='80%' onClick={() => { setImagen(remito) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>

            <p>Podremos generar un qr, el cual podremos imprimir.</p>
            <ul>
            <li>N° Remito</li>
            <li>Cantidad de bultos</li>
            <li>Bultos</li>
            </ul>
            <br />
            <p>Podremos buscar la opción de buscar algún dato en la casilla <b>Buscar</b> y descargar el cuadro como planilla de Excel clickeando el icono del mismo. </p>
            <div className='d-flex justify-content-left'>
                <img src={busqueda} alt='busqueda' width='90%' />
            </div>


            <div style={{marginTop: "15rem"}} id="repuestos"></div>

            <h4 style={{marginBottom: "2rem"}}>REPUESTOS</h4>

            <p>En esta sección podemos ver las solicitudes de repuestos donde te muestra un cuadro te muestra:</p>
            <ul>
            <li>Fecha</li>
            <li>Nro de orden</li>
            <li>Técnico</li>
            <li>Cliente</li>
            <li>Repuesto</li>
            <li>Descripción</li>
            <li>Cantidad</li>
            <li>Garantía</li>
            <li>Nro de Factura</li>
            <li>Comercio</li>
            <li>Estado</li>
            </ul>
            <br />
            <p>La tabla se ve asi.</p>
            <div className='d-flex justify-content-center'>
                <img src={tabla5} alt='tabla5' height='80%' width='80%' onClick={() => { setImagen(tabla5) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
            </div>
            <br />
            <p>Al igual que las demas tablas, tambien tendremos la opción de buscar algún dato en la casilla <b>Buscar</b> y de descargar el cuadro como planilla de Excel clickeando el icono del mismo.</p>
            <p>En el lado derecho de "buscar", tendremos la opción de <b>"Filtrar por estado"</b> donde si clickeamos podemos ver los diferentes estaods de las ordenes.</p>
            <p>Podremos agrandar el cuadro desde la página clickeando en el icono que se ve arriba a la derecha.</p>

            <br/>
            <p>Y al final de las secciones tenemos el boton de cerrar de sesión. <img src={cerrar} alt='cerrar' height='2%' width='2%' style={{marginLeft: "5px"}}/></p>

            
                
        </div>
    )
}