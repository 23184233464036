import React, { useState, useEffect } from 'react';
import { GetTalleres, GetFactura, updatestatefactura } from './dbfirebase';
import { toast } from 'react-toastify';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export const Facturacion = () => {
   
    const [factura, setFactura] = useState([]);
    const [filas, setFilas] = useState()
    const [state, setState] = useState()
    
    useEffect(() => {
        const fetchDataFactura = async () => {
            try {
                const facturas = await GetFactura();
    
                // Agrupar las facturas por el nombre del taller
                const facturasPorTaller = [];
                facturas.forEach(factura => {
                    const nombreTaller = factura.Taller;
                    const indice = facturasPorTaller.findIndex(item => item.nombre === nombreTaller);
                    if (indice !== -1) {
                        // Si el taller ya existe en el array, agregamos la factura al arreglo correspondiente
                        facturasPorTaller[indice].facturas.push(factura);
                    } else {
                        // Si el taller no existe en el array, creamos un nuevo objeto con el nombre del taller y la factura
                        facturasPorTaller.push({ nombre: nombreTaller, facturas: [factura] });
                    }
                });
    
                // Establecer las facturas agrupadas por taller
                setFactura(facturasPorTaller);
                //console.log(facturasPorTaller)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchDataFactura();
    }, []);
    
    
    useEffect(()=>{
        let filas = [];
        // Verificar si existe el campo "Repuestos" y si tiene valores
        factura && factura.map((datos)=>{
            datos.facturas.map((dato) => {
                if (dato.Ordenes && Array.isArray(dato.Ordenes)) {
                   
                    dato.Ordenes.forEach((ordenes) => {
                        if (dato.Estado !== 'Cerrado' && !dato.Taller.startsWith('Goldmund')) {
                            const fila = {
                                Fecha: new Date(dato.Fecha.seconds * 1000).toLocaleString(),
                                Estado: dato.Estado,
                                iddoc: dato.iddoc,
                                Taller: dato.Taller,
                                Titular: dato.Titular,
                                Total: dato.Total,
                                Ordenes: [{
                                    Fecha: ordenes.Fecha.toLocaleString(),
                                    Orden: ordenes.Num_Os,
                                    Articulo: ordenes.Articulo,
                                    Descripcion: ordenes.Descripcion,
                                    Categoria: ordenes.Categoria,
                                    Cliente: ordenes.Cliente,
                                    Desperfecto: ordenes.Desperfecto_declarado,
                                    Costo: ordenes.ManoDeObra,
                                    iddoc: ordenes.iddoc,
                                    FechaCompra: ordenes.FechaCompra,
                                    RazonSocialCli: ordenes.RazonSocialCli,
                                    NumFactura: ordenes.NumFactura,
                                    Conforme: ordenes.MotivoCierre[ordenes.MotivoCierre.length -1]
                                }]
                            }
                            
                            filas.push(fila);
                    }
                
                    });
                }
            })
            
        })
        setFilas(filas)
        // setRemitosPendientes(filas.filter((etiqueta) => etiqueta.UltEstado === "Pendiente"));
    },[factura])

    const titularesPendientesUnicos = filas && [
        ...new Set(
          filas
            .filter(filtrado => filtrado.Estado === "Pendiente")
            .map(filtrado => filtrado.Titular)
        )
      ];
      
    const filasAgrupadas = filas && filas.reduce((acc, item) => {
        if (!acc[item.Titular]) {
        acc[item.Titular] = [];
        }
        acc[item.Titular].push(item);
        return acc;
    }, {});

    const UpdateStateChange = async(idfactura, state) =>{
        await updatestatefactura(idfactura, state)
        setState(state)

        toast.success('Datos cargados con éxito', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true
        });
        // setTimeout(()=>{ 
        //     window.location.reload();

        // }, 2500)
    }

    const extractUrl = (text) => {
        // Expresión regular para encontrar URLs
        const urlPattern = /(https?:\/\/[^\s]+)/g;
    
        // Buscar la URL en el texto
        const matches = text.match(urlPattern);
    
        // Retornar la primera coincidencia o null si no se encuentra ninguna URL
        return matches ? matches[0] : null;
    }

    const downloadFile = (url) => {
        if (!url || typeof url !== 'string'){
            console.log("URL no valida")
        }else{
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = "_blank";
            // anchor.download = '';
            anchor.click();
        }        
    }

    const showImageModal = async (rutaImagen) => {
        const extensions = ['jpg', 'jpeg', 'png', 'pdf'];
        try {
            const storage = getStorage();
            let imageUrl = null;
            for (const extension of extensions) {
                try {
                    const imagenRef = ref(storage, rutaImagen + '.' + extension);
                    imageUrl = await getDownloadURL(imagenRef);
                    // Si encontramos la imagen, salimos del bucle
                    break;
                } catch (error) {
                    // Si hay un error, probamos con la siguiente extensión

                }
            }
            if (imageUrl) {   
                downloadFile(imageUrl)
            } else {
                console.error("No se pudo encontrar la imagen.");
            }
        } catch (error) {
            console.error("Error al obtener la imagen:", error);
            // Manejar el error adecuadamente
        }
    }
    
    let agrupadasYlote = {};
    filasAgrupadas && Object.entries(filasAgrupadas).forEach(([lote, items], index) => {
        const agrupadas = items && items.reduce((acc, item) => {
            if (!acc[item.iddoc]) {
                acc[item.iddoc] = [];
            }
            acc[item.iddoc].push(item);
            return acc;
        }, {});
        agrupadasYlote[lote] = agrupadas;
    });

    // const ordenesAgrupadas = {};
    // {agrupadasYlote && Object.entries(agrupadasYlote).forEach(([lote, items], index) => {
    //     Object.entries(items).map((item, index) => (
            
    //         Object.entries(item)[1][1].forEach((i) => {
    //             const { iddoc, Ordenes } = i;
    //             // Verifica si el iddoc ya está en el objeto
    //             if (!ordenesAgrupadas[iddoc]) {
    //                 // Si no existe, crea una nueva entrada con un array vacío
    //                 ordenesAgrupadas[iddoc] = [];
    //             }
    //             // Agrega las órdenes al array correspondiente al iddoc
    //             ordenesAgrupadas[iddoc].push(...Ordenes)
    //         })            
    //     ))
    // })}
    
    
    return (
        <div>
            {/* <ModalFactura onSubmit={facturar} /> */}
            <h3 className='d-flex'>Facturacion de Talleres:</h3>
            {agrupadasYlote && Object.entries(agrupadasYlote).map(([lote, itemss], index) => {
                const isTitularPendiente = titularesPendientesUnicos.includes(lote);
            return(
            <div className="accordion" id="accordion" style={{marginTop: '50px'}} key={`acordeon1-${index}`}>
                {/* Mapear cada taller para crear un desplegable */}
                {/* {Object.keys(factura).map((nombreTaller, index) => ( */}
                       

                <div className="accordion-item" >
                    
                    <div className="row accordion-button collapsed m-auto" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls="collapseThree">
                        <div className={`col alert ${isTitularPendiente? 'alert-warning':'alert-success'}`} role="alert">
                            <h4 className="accordion-header" id="headingThree">
                                Facturas: {lote}
                            </h4>
                        </div>
                    </div>

                    {Object.entries(itemss).map((items, indx) => {
                        const ordenesAgrupadas = {};
                        Object.entries(items)[1][1].forEach((i) => {
                            const { iddoc, Ordenes } = i;
                            // Verifica si el iddoc ya está en el objeto
                            if (!ordenesAgrupadas[iddoc]) {
                                // Si no existe, crea una nueva entrada con un array vacío
                                ordenesAgrupadas[iddoc] = [];
                            }
                            // Agrega las órdenes al array correspondiente al iddoc
                            ordenesAgrupadas[iddoc].push(...Ordenes)
                        })
                        // console.log(ordenesAgrupadas)
                        return(
                    <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion" key={`collapse-${index}-${indx}`}>
                        <div className="accordion-body">
                            <div className='row' style={{marginTop: '25px'}}>
                                <div className='container'>
                                    <div>
                                        <div>
                                            {/* ####  Revisar, esta mal calculado #### */}
                                            {/* <p>
                                                Cantidad de facturas pendientes: {items.reduce((total, item) => {
                                                    if (item.Estado === 'Pendiente') {
                                                        return total + 1;
                                                    }
                                                    return total;
                                                }, 0)}
                                            </p> */}
                                        </div>
                                        <div style={{marginTop: '15px'}}>                                            
                                            <div className="accordion" key={`acordeon-${index}-${indx}`}>
                                    
                                                {/* {Object.entries(items).map(([id, item], inx) => ( */}
                                                    <div className="card" key={`card-${index}-${indx}`}>
                                                    
                                                        <div className="card-header row" id={`heading-${indx}`} 
                                                            role="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse2-${indx}`}
                                                            aria-expanded="true"
                                                            aria-controls={`collapse2-${indx}`}>
                                                                <div className="col">
                                                                    {/* <p>{`card-${index}-${indx}`}</p> */}
                                                                    <h2>
                                                                        Registro: {`${Object.entries(items)[1][1][0]['iddoc']}`}
                                                                        <p>{`${Object.entries(items)[1][1][0]['Fecha']}`}</p>
                                                                    </h2>
                                                                    <label>
                                                                        Fecha: {`${Object.entries(items)[1][1][0]['Fecha']}`}
                                                                    </label>
                                                                    <h5>
                                                                        Total: ${`${Object.entries(items)[1][1][0]['Total']}`}
                                                                    </h5>
                                                                </div>
                                                                <div className="col d-flex justify-content-end">
                                                                    <h3>
                                                                        Estado: {`${Object.entries(items)[1][1][0]['Estado']}`}
                                                                    </h3>
                                                                </div>                                                    
                                                        </div>
                                                        
                                                        <div
                                                            id={`collapse2-${indx}`}
                                                            className="collapse"
                                                            aria-labelledby={`heading-${indx}`}
                                                            data-parent="#accordionExample"
                                                        >
                                                            <div className="card-body" style={{overflow: 'auto', maxHeight: '400px'}}>
                                                                
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Fecha</th>
                                                                            <th>Orden</th>
                                                                            <th>Articulo</th>
                                                                            <th>Descripción</th>
                                                                            <th>Categoria</th>
                                                                            <th>Cliente</th>
                                                                            <th>Desperfecto</th>
                                                                            <th>Costo</th>
                                                                            <th>Factura</th>
                                                                            <th>Conforme</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    {Object.entries(ordenesAgrupadas).map(([id, item], iex) => (
                                                                        item.map((i, ix) =>{
                                                                            return(
                                                                            <tbody key={`table-${index}-${indx}-${iex}-${ix}`}>
                                                                                <tr >
                                                                                    <td>{i.Fecha}</td>
                                                                                    <td>{i.Orden}</td>
                                                                                    <td>{i.Articulo}</td>
                                                                                    <td>{i.Descripcion}</td>
                                                                                    <td>{i.Categoria}</td>
                                                                                    <td>{i.Cliente}</td>
                                                                                    <td>{i.Desperfecto}</td>
                                                                                    <td>{i.Costo?i.Costo:0}</td>
                                                                                    <td>
                                                                                        <button className="btn btn-secondary" onClick={() => showImageModal(`/factura_de_compras/${i.iddoc}_${i.FechaCompra}_${i.RazonSocialCli}_${i.NumFactura}`)}>Factura</button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-secondary" onClick={() => downloadFile(extractUrl(i.Conforme))}>Conforme</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            )}
                                                                        )
                                                                    )       
                                                                    )}
                                                                </table>
                                                                
                                                            </div>
                                                            <div className="row" style={{marginTop: '15px'}}>
                                                                <div className="col" style={{margin: '1rem'}}>
                                                                    <a className="col d-flex" href="#" onClick={() => {showImageModal('/FacturasCierreOrdenes/'+ `${Object.entries(items)[1][1][0]['iddoc']}`)}} style={{width: "fit-content"}}>Ver factura</a>
                                                                </div>
                                                                <div className="btn-group dropup" style={{width: 'fit-content'}}>
                                                                    <button type="button" style={{margin: '1rem', width:'10rem'}} className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        {`${Object.entries(items)[1][1][0]['Estado']}`}
                                                                    </button>
                                                                    <div className="dropdown-menu " data-popper-placement="let-start">
                                                                        <button className="dropdown-item" onClick={() => UpdateStateChange(`${Object.entries(items)[1][1][0]['iddoc']}`, "Rev. de Pago")}>Revision de pago</button>
                                                                    </div>    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                {/* ))}  */}
                                            </div>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)})}    
                </div>
            
            </div>
            )})}
        </div>
    );
};