import React, { useEffect, useRef } from 'react'
import {Print} from './print'
import { useReactToPrint } from 'react-to-print';
import QRGenerator from './QRGenerator';

export const ModalEtiquetasQr = (etiquetas) => {
    
    const Qrdata = etiquetas.data.Remito+" "+etiquetas.data.Nombre+" "+etiquetas.data.Bultos
    const OrderRef = useRef(null)
    const componentRef = useRef(null);
    
    const closeModal = () => {
        const modal = document.getElementById('ModalEtiquetasQr');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
        
    };

    const handleCloseModal = () => {
         closeModal();   
    };

   window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => { 
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
      });

    return (
        
            <div className="modal" 
                id="ModalEtiquetasQr" 
                tabIndex={-1} 
                aria-labelledby="ModalEtiquetasQr" 
                aria-hidden="true"
                ref={OrderRef}>
                <div className="modal-dialog modal-fullscreen-lg-down modal-lg ">
                    <div className="modal-content  border-0 colorBody rounded-0 modalProd">

                        {/* Encabezado */}
                        <div className="modal-header d-flex border-0 stickyHead">
                            <h5 className='mb-0 textColorGreyM' id='productModalLabel'>
                                Imprimir etiqueta del remito seleccionado:
                            </h5>
                            <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                        </div>

                        {/* Body 1*/}
                        <div className="modal-body m-4" ref={componentRef}>

                                {etiquetas.data.Remito &&
                                    <div className="row">
                                        <div className="col-6">   
                                        <p>Remito: {etiquetas.data.Remito}</p>
                                        <p>Nombre: {etiquetas.data.Nombre}</p>
                                        <p>Cantidad de Bultos: {etiquetas.data.Bultos}</p> 
                                        </div>
                                        <QRGenerator data={Qrdata}/>
                                    </div>
                                    
                                }              
                            
                        </div>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={handlePrint}
                        >
                            Imprimir Todo
                        </button>
                    </div>
                </div>
            </div>
        
    )
}