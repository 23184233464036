import React, { useState, useEffect } from 'react';
import {useAuth} from "../context/authcontext";
import {Actualizar} from './dbfirebase'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export const BarScanner = ({setData, setState}) => {
    
    const location = useLocation();
    const currentPath = location.pathname;
    
    const [result, setResult] = useState('');
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [barcodeValue, setBarcodeValue] = useState('');
    const [actualizado, setActualizado] = useState()
    const [estados, setEstados] = useState({
        enCurso: false,
        despacho: false,
        sinStock: false,
      });
    const [enviar, setEnviar] = useState()
    const {user} = useAuth()
    const scanTimeout = 30000; // 30 segundos

   // Función para manejar cambios en el input
    // const handleInputChange = (e) => {
    //     const inputValue = e.target.value;
    //     const modifiedValue = inputValue.replace(/'/g, '-');
    //     setBarcodeValue(modifiedValue);
    //     //setBarcodeValue(e.target.value);f
    // };
    
    let inputTimer
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const modifiedValue = inputValue.replace(/'/g, '-');
        setBarcodeValue(modifiedValue);
        setData((prevData) => [...prevData, modifiedValue])
        clearTimeout(inputTimer);
        inputTimer = setTimeout(() => {
            setEnviar(true)
          // Hacer algo con el código de barras completo
        }, 1000);
      };
    
    const handleCheckboxChange = (selectedCheckbox) => {
        // Crear un nuevo objeto de estados
        const newEstados = { ...estados };
      
        // Desmarcar todos los checkboxes
        Object.keys(newEstados).forEach((checkbox) => {
          newEstados[checkbox] = false;
        });
      
        // Marcar el checkbox seleccionado
        newEstados[selectedCheckbox] = !estados[selectedCheckbox];
      
        // Actualizar el estado
        setEstados(newEstados);
        setState(selectedCheckbox)
    };

    const estadosSeleccionados = Object.keys(estados).filter(
    (estado) => estados[estado]
    );
    
    const actualizar = async(barcodeValue) =>{
        if (estadosSeleccionados.length > 0 && enviar === true && currentPath === '/repuestos') {
            const actualizacion = await Actualizar(estadosSeleccionados, barcodeValue, user, setActualizado)
            setEnviar(false)
            // toast.success("Actualizado",{
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true
            // })
            setBarcodeValue('');
        }else{
            // toast.error("Debe seleccionar una accion.",{
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true
            // })
            setBarcodeValue('');
            setEnviar(false)
        }
    }

    useEffect(()=>{
        if (enviar){
            actualizar(barcodeValue)
        }else{
            setBarcodeValue('');
        }          
    },[enviar])
    

  // Verificar si es dispositivo móvil
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        setIsMobile(/android|iPad|iPhone|iPod/.test(userAgent));
    }, []);

    useEffect(() => {
        let scanInterval;
        
        // Función para cerrar la cámara y restablecer el resultado
        const closeCamera = () => {
        setIsCameraOpen(false);
        setResult('');
        };

        // Reiniciar el temporizador
        const resetTimer = () => {
        if (scanInterval) {
            clearInterval(scanInterval);
        }
        scanInterval = setInterval(closeCamera, scanTimeout);
        };

        resetTimer();

        return () => {
        // Limpiar el intervalo cuando el componente se desmonta
        clearInterval(scanInterval);
        };
    }, []);

    // const handleScan = (data) => {
    //     if (data) {
    //         setResult(data);
    //         setIsCameraOpen(false);
    //     }
    // };

    const handleError = (err) => {
        console.error(err);
    };
    // Limpia los recursos y detiene el acceso a la cámara cuando el componente se desmonta
    useEffect(() => {
        return () => {
            setResult('');
        };
    }, []);

    const isAnyCheckboxSelected = Object.values(estados).some((value) => value);

  return (
    <div className="col contenedor">
      
        <ToastContainer />
        {/* Input para recibir datos del lector de código de barras */}
        <input
            type="text"
            placeholder="Escanear código de barras"
            value={barcodeValue}
            onChange={handleInputChange}
            style={{width:'17rem'}}
            disabled={!isAnyCheckboxSelected}
            autoFocus={isAnyCheckboxSelected}  
        />
        <div className="p-2">
         
            <label>
            <input
                type="checkbox"
                checked={estados.enCurso}
                onChange={() => handleCheckboxChange('enCurso')}
            />
                En Curso
            </label>
        </div>

        
        <div className="p-2">
            <label>
                <input
                    type="checkbox"
                    checked={estados.despacho}
                    onChange={() => handleCheckboxChange('despacho')}
                />
                    Despacho
            </label>
        </div>
        

        <div className="p-2">
            <label>
            <input
                type="checkbox"
                checked={estados.sinStock}
                onChange={() => handleCheckboxChange('sinStock')}
            />
                Sin Stock
            </label>
        </div>
        {/* {currentPath === '/repuestos'?
            <div className="p-2">
                <a href='/despacho'>Despachante</a>
            </div>:''
        } */}
    </div>
  );
};
