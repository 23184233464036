import React, { useState } from 'react';

export const TarjetaAcordeon = ({ data }) => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <div id="accordion">
            <h2>Datos:</h2>
            <label className="mx-3">Buscar:</label>
            <input
                type="text"
                placeholder="Buscar..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{marginTop:"2rem", marginBottom:"2rem"}}
            />
       {data.filter((item) =>
            item.iddoc.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.Cliente.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.Estado.toLowerCase().includes(searchValue.toLowerCase())
        // Agrega más campos aquí según tus necesidades
      ).map((item, index) => (
            <div key={index} className="card">
            <div className="card-header" id={`heading${index}`} data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}>
                <h6 className="mb-0">
                <label>
                    N° Orden: {item.iddoc}
                </label>
                <label>
                    Cliente: {item.Cliente}
                </label>
                <label>
                    Estado: {item.Estado}
                </label>
                </h6>
            </div>

            <div
                id={`collapse${index}`}
                className="collapse"
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
            >
                <div className="card-body">
                <p>Fecha: {item.Fecha}</p>
                <p>Cuit/Dni: {item.CuitDni_cli}</p>
                <p>Telefono: {item.Telefono_cli}</p>
                <p>Articulo: {item.Articulo}</p>
                <p>Garantia: {item.Garantia?'SI':'No'}</p>
                <p>Comercio: {item.RazonSocialCli}</p>
                <p>Desperfecto: {item.Desperfecto}</p>
                <p>Estado: {item.Estado}</p>
                <p>Detalle: {item.Comentarios}</p>
                </div>
            </div>
            </div>
        ))}
        </div>
    );
};
