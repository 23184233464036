import React from 'react';

export const GlobalFilter = ({filter, setFilter}) => {
    return (
        <span>
            Buscar: {' '}
            <input value = {filter || ''} onChange = {(e) => {setFilter(e.target.value)}} style={{width: '15rem'}}/>
        </span>
    )
}

export const MultiGlobalFilter = ({ filters, setFilters }) => {
    const handleFilterChange = (value) => {
      setFilters({
        ...filters,
        value
      });
    };
  
    return (
      <div>
        <label>Buscar por Taller: </label>
        <input
        //   value={filters.taller || ''}
          onChange={(e) => handleFilterChange(e.target.value)}
          style={{ width: '15rem' }}
        />
        <label>Buscar por Estado: </label>
        <input
        //   value={filters.estado || ''}
          onChange={(e) => handleFilterChange(e.target.value)}
          style={{ width: '15rem' }}
        />
        {/* Agrega más campos de búsqueda si es necesario */}
      </div>
    );
};
  
  

export const StateFilter = ({filter, setFilter}) => {
    return (
        <div className="btn-group dropright">
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado:
            </button>
            <div className="dropdown-menu">
                <button className='dropdown-item' onClick = {() => {setFilter('Pendiente')}} style={{width: '10rem'}} >Pendientes</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Repuestos')}} style={{width: '10rem'}}>Repuestos</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Diagnosticado')}} style={{width: '10rem'}}>Diagnosticado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Reparado')}} style={{width: '10rem'}}>Reparado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Presupuestado')}} style={{width: '10rem'}}>Presupuestado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('PresAceptado')}} style={{width: '10rem'}}>Presupuesto acept.</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Finalizado')}} style={{width: '10rem'}}>Finalizado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Despachado')}} style={{width: '10rem'}}>Despachado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Retirar')}} style={{width: '10rem'}}>Retirar</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Cerrado')}} style={{width: '10rem'}}>Cerrado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Facturado')}} style={{width: '10rem'}}>Facturado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('')}} style={{width: '10rem'}}>Quitar filtro</button>
            </div>
        </div>
    )
}

export const DateFilter = ({filter, setFilter}) => {
    const currentDate = new Date().toISOString().split('T')[0];

    const handleDateChange = (event) => {
        const date = event.target.value;
        const partesFecha = date.split('-');
        const fechaInvertida = `${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`;
        const parteFecha = fechaInvertida.split('/');
        const dia = parseInt(parteFecha[0]);
        const mes = parseInt(parteFecha[1]);
        const anio = parseInt(parteFecha[2]);
        const fechaSinCeros = `${dia}/${mes}/${anio}`;
        setFilter(fechaSinCeros);
      };

    return (
        <div className="btn-group dropright">
        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Filtrar por fecha:
        </button>
        <div className="dropdown-menu">
            <input
                type="date"
                className="dropdown-item"
                onChange={handleDateChange}
                value={filter}
                style={{ width: '10rem' }}
                max={currentDate}
            />
        </div>
    </div>
    )
}

export const StateFilterRepuestos = ({filter, setFilter}) => {
    
    return (
        <div className="btn-group dropright" >
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado:
            </button> 
            <div className="dropdown-menu dropdown-menu-right">
                <button className='dropdown-item' onClick = {() => {setFilter('Pendiente')}} style={{width: '10rem'}} >Pendientes</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Repuestos')}} style={{width: '10rem'}}>Repuestos</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Despacho')}} style={{width: '10rem'}}>En despacho</button>
                <button className='dropdown-item' onClick = {() => {setFilter('SinStock')}} style={{width: '10rem'}}>Sin stock</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Transporte')}} style={{width: '10rem'}}>En transporte</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Autorizado')}} style={{width: '10rem'}}>Autorizados</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Cancelado')}} style={{width: '10rem'}}>Cancelados</button>
                <button className='dropdown-item' onClick = {() => {setFilter('enCurso')}} style={{width: '10rem'}}>En curso</button>
                <button className='dropdown-item' onClick = {() => {setFilter('')}} style={{width: '10rem'}}>Quitar filtro</button>
            </div>
        </div>
    )
}

