import { configureStore } from '@reduxjs/toolkit'
import newOrderReducer from './orderInfo/newOrderSlice'
import productDataReducer from './productInfo/productDataSlice';
import categoriasDataReducer from './productInfo/categoriasDataSlice'
import ordersInfoReducer from './orderInfo/ordersInfoSlices'
import userInfoReducer from './userInfo/userInfoSlices'
import sapInfoSlice from './SapInfo/SapInfoSlice'
import  retailInfoSlice  from './orderInfo/retailInfo';
import tallerInfoSlice from './orderInfo/tallerInfo';

export const store = configureStore({
    reducer: {
        newOrder: newOrderReducer,
        productData: productDataReducer,
        categoriaData: categoriasDataReducer,
        ordersInfo: ordersInfoReducer,
        userInfo: userInfoReducer,
        SapInfo: sapInfoSlice,
        retailInfo:retailInfoSlice,
        tallerInfo:tallerInfoSlice,
    }
})