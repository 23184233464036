import React, {useEffect, useState}from "react"
import {GetRepuestos, GetCategorias, GetDolar, addPedidoRepuesto, Getpvp} from './dbfirebase'
import { ToastContainer, toast } from 'react-toastify';
import Autocomplete from 'react-google-autocomplete';
import {Table} from './table'
import {columnsRepuestosCF, columnsRepuestosConfirmar} from './columns';
import {doc, collection} from "firebase/firestore";
import {db} from "../components/firebase";
import {useGeneral} from '../context/generalContext'
import deleterow from '../images/delete.png'
import { useSelector } from 'react-redux';

export const PedidosClientes = () => {

    const [dato, setDato] = useState(false)
    const [listaprecios, setListaprecios] = useState([])
    const [repuestos, setRepuestos] = useState('')
    
    const categorias = useSelector(state => state.categoriaData.categorias)
    const sku = useSelector(state => state.productData.products)
    const [nuevaListaPedidoFiltrado, setNuevaListaPedidoFiltrado] = useState([]);
    const [cdolar, setCdolar] = useState(1)
    const [pedido, setPedido] = useState([])
    const [listaPedido, setListaPedido] = useState([])
    const [pedidofiltrado, setPedidofiltrado] = useState()
    const [iddoc, setIdDoc] = useState()
    const [pvp, setPvp] = useState(1)
    const [userdatos, setUserdatos] = useState()
    const [producto, setProducto] = useState()
    const [order, setOrder] = useState({
        Actualizado: [],
        Cliente: '',
        Domicilio_cli: '',
        CuitDni_cli: '',
        CodigoPostal_cli: '',
        Telefono_cli: '',
        Email_cli: '',
        Categoria: '',
        Articulo: '',
        Desperfecto: '',
        Comentarios: '',
        Garantia: false,
        Taller: '',
        SparePart:true,
        Tipo: '',
        Estado: 'Repuestos',
        Fecha: new Date().toLocaleString().split(',')[0],
        TimeStamp: new Date(),
        Repuestos: [],
        Mdo: '-',
        Firmar:'-',
        Num_Serie:'-',
        Num_Os:'-',
        Transporte:"-",
        Latitud: '-',
        Longitud: '-',
    })
    const [place, setPlace] = useState('')
    const{userData} = useGeneral()

    const perfil = async() =>{
        const dato = await userData
        setUserdatos(dato)
    }
    

    setTimeout(perfil, 1000)

    const docRef = doc(collection(db, "Ordenes"))
    const id = docRef.id
    
    useEffect(()=>{
        let number = []
        const charArray = id.substring(5, 10).split("");
        charArray.map((char) => {
            let numb = char.charCodeAt()
            number.push(numb)
        })
        // Usamos el numero generado como clave secreta
        const secretKey = number.join('').toString()
        setIdDoc(secretKey)
    },[])

    
    const Repuestos = async(categoria, articulo) => {
        const Articulos = await GetRepuestos(categoria, articulo)
        setRepuestos(Articulos)
    }

    const Pvp = async() =>{
        const d = await Getpvp()
        setPvp(d)
    }

    useEffect(()=>{
        Pvp()
    },[])

    const obj = {}
        categorias.map((item) =>{
        [item].map((i)=>{
            Object.assign(obj, i);
        })
    }) 

    function buscarClave(obj, valorBuscado) {
        for (let i=0; i<=obj.length; i++){
            for (let clave in obj[i]) {
                if (clave === valorBuscado[0]) {
                    return obj[i].Grupo;
                }
            }
        }
        return null; // valor no encontrado
    }
    
    const buscarCategoria = (objeto, claveBuscada, ruta = []) => {
        for (let nombreCategoria in objeto) {
            let categoria = objeto[nombreCategoria];
            if (typeof categoria === 'object') {
                if (claveBuscada in categoria) {
                    ruta.push(nombreCategoria);
                    ruta.push(claveBuscada);
                    return ruta;
                } else {
                    let resultado = buscarCategoria(categoria, claveBuscada, ruta.concat(nombreCategoria));
                    if (resultado !== false) {
                        let grupo = buscarClave(categorias,resultado)
                        return [resultado, grupo]
                    }
                }
            }
        }
        return false;
    }

    useEffect(() =>{
        const cate = buscarCategoria(obj, order.Articulo)[1]

        if (cate){
            const type = buscarCategoria(obj, order.Articulo)[0][0]
            Repuestos(cate, order.Articulo)
            // Actualiza el estado con el valor de la categoría
            setOrder(prevTaller => ({
                ...prevTaller,
                Categoria: cate,
                Tipo: type
            }));
        }
    },[order.Articulo])
    
    
    const lista = []

    useEffect(()=>{
        const listaprecio = []
        if(repuestos[0]){
            const nuevosProductos = repuestos && Object.entries(repuestos[0]).map((repuestos) => {
                repuestos[1].map((r) =>{
                   
                    const PrecioPublico = Object.values(r)[0].PrecioPublico * parseInt(producto?producto.Price:1)
                    const PrecioTaller = Object.values(r)[0].PrecioTaller * parseInt(producto?producto.Price:1)
                    const Descripcion = Object.values(r)[0].Descripción
                    const FechaAct = Object.values(r)[0].FechaAct
                    const aplicado = Object.values(r)[0].aplicado
                    const c = categorias.map((categoria) => (Object.keys(categoria).includes('Categoria') ? categoria.Categoria[`${order.Categoria.replace(/\s/g, '')}`]:null)).filter((valor) => valor !== null)
                    lista.push({Categoria:order.Categoria.replace(/\s/g, '')||'', Tipo:order.Tipo.replace(/\s/g, ''), Articulo:order.Articulo, Codigo: Object.keys(r), PrecioPublico, PrecioTaller, Descripcion, FechaAct, aplicado })
                });
                return lista
              });
              
              listaprecio.push(lista)
              
        };
        setListaprecios(listaprecio)
        // setUpdate(false)
    },[repuestos])

    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setOrder((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(()=>{
        setListaPedido([...listaPedido, pedido[0]])
    },[pedido])

    
    const filtrado = () => {
        const filteredPedido = listaPedido.slice(1);
        // Eliminar registros duplicados y quedarse con el último registro para cada artículo
        const uniquePedido = filteredPedido.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex((x) => x['Codigo[0]'] === item['Codigo[0]']);
            if (existingItemIndex !== -1) {
                const existingItem = acc[existingItemIndex];
                if (item.Ult_Act > existingItem.Ult_Act) {
                    acc[existingItemIndex] = item;
                }
            } else {
                const newItem = { ...item };
                delete newItem['Codigo[0]'];
                acc.push({ ...newItem, Articulo: item['Codigo[0]'], UltEstado:"Pendiente", Fecha:new Date().toLocaleString().split(',')[0]});;
            }
            return acc;
        }, []);
    
        // Eliminar elementos con cantidad igual a 0
        const finalPedido = uniquePedido.filter(item => item.cantidad !== "0");
        setPedidofiltrado(finalPedido);
        return finalPedido;
    };
     

        const handleEliminarRepuesto = (fila) => {
        // Suponiendo que 'pedidofiltrado' es una parte del estado del componente
        // Clona 'pedidofiltrado' para evitar la mutación directa del estado
        const nuevaListaPedidoFiltrado = [...pedidofiltrado];
      
        // Encuentra el índice de la fila que se va a eliminar
        const index = nuevaListaPedidoFiltrado.findIndex((f) => f === fila);
      
        // Si se encontró la fila, elimínala del arreglo
        if (index !== -1) {
          nuevaListaPedidoFiltrado.splice(index, 1);
      
          // Actualiza el estado con la nueva lista sin la fila eliminada
          setPedidofiltrado(nuevaListaPedidoFiltrado);
          
        }
      };
    
    
    
      
    const ClearForm = () => { 
        document.getElementById("Nombre").value = ''
        document.getElementById("Cuit/Dni").value = ''
        document.getElementById("domicilio").value = ''
        document.getElementById("telefono").value = ''
        document.getElementById("email").value = ''
        document.getElementById("articulo").value = ''
        document.getElementById("observaciones").value = ''
    }
        
    // useEffect(()=>{
    //     filtrado()
    // },[listaPedido])

    useEffect(()=>{
        setOrder((prevOrder) => ({...prevOrder, Repuestos:pedidofiltrado}))
    },[pedidofiltrado])

    const AddSparepartsRequest = async() =>{   
        try{
            const ord = {...order, ...{iddoc:iddoc}, ...userdatos[0]}
            const upload = await addPedidoRepuesto(docRef, ord)
            ClearForm()
            setPedidofiltrado('')
            toast.success('Datos cargados con exito',{
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true})
        }catch{
            toast.error("A ocurrido un error",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            }) 
        }
    }

    useEffect(() => {
        if (pvp && pvp.Precios) { 
          const productoFiltrado = pvp.Precios.find((producto) => producto.ItemCode === order.Articulo);
          setProducto(productoFiltrado);
        }
      }, [order.Articulo, pvp]);
    
      
    
    
    return(
        <div>
            <ToastContainer/>
            <h1>
                Solicitud de repuestos:
            </h1>
            <br/>
            <div className='ms-4 row'>
                <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }} >
                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                    <input 
                        className= "form-control"
                        id = "Nombre"
                        style={{maxWidth:'25rem'}}
                        name="Cliente"
                        autoFocus
                        onChange={handleForm1Change}                        
                    />
                    
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit-Dni:</label>
                    <input 
                        className= "form-control"
                        id = "Cuit/Dni"
                        style={{maxWidth:'25rem'}}
                        name="CuitDni_cli"
                        onChange={handleForm1Change}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Domicilio:</label>
                    <Autocomplete
                        name='Domicilio_cli'
                        className='form-control'
                        style={{ maxWidth: '25rem' }}
                        onChange={(value) => {
                                    // chekea si el valor es valido, seleccionado de google 
                            if (!value || value === "Error") {
                                // muestra que la opcion es incorrecta 
                                toast.error('Opción incorrecta seleccionada', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true
                                });
                            }
                    
                            // la logica del onchange no se modifica
                            handleForm1Change(value);
                        }}
                        placeholder='Calle, localidad, provincia'
                        id='domicilio'
                        apiKey={process.env.REACT_APP_MAP} // Reemplazar con la API key de Google Places
                        onPlaceSelected={(place, autocomplete) => {

                            if (!place || !place.address_components) {
                                // Maneja el caso en que el usuario comete un error de escritur
                                toast.error('Por favor, selecciona un lugar válido', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true
                                });
                        
                                return;
                            }
                            // Obtén la dirección del lugar seleccionado y actualiza el estado 'taller'
                            const addressComponents = place.address_components || [];
                            let direccion = '';
                            let localidad = '';
                            let provincia = '';
                            let latitud = '';
                            let longitud = '';

                            addressComponents.forEach((component) => {
                                if (component.types.includes('street_number') || component.types.includes('route')) {
                                    direccion += component.long_name + ' ';
                                }
                                if (component.types.includes('locality')) {
                                    localidad = component.long_name;
                                }
                                if (component.types.includes('administrative_area_level_1')) {
                                    provincia = component.long_name;
                                }
                                // Puedes agregar más lógica aquí para otros componentes de dirección si es necesario
                            });

                            // Obtener latitud y longitud del lugar seleccionado
                            latitud = place.geometry.location.lat();
                            longitud = place.geometry.location.lng();

                            // Actualiza el estado 'taller'
                            setOrder((prevCliente) => ({
                                ...prevCliente,
                                Domicilio_cli: direccion.trim(),
                                Localidad_cli: localidad,
                                Provincia_cli: provincia,
                                Latitud: latitud,
                                Longitud: longitud,
                            }));
                            setPlace(place)
                        }}
                        options={{
                            types: ['address'],
                            componentRestrictions: { country: 'ar' },
                            //fields: ['postal_code']
                        }}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        name="Telefono_cli"
                        id="telefono"
                        onChange={handleForm1Change}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-start' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>E-mail:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        name="Email_cli"
                        id="email"
                        onChange={handleForm1Change}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Articulo:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        name="Articulo"
                        id="articulo"
                        list="Articulo"
                        autoComplete="off"
                        onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleForm1Change(e); }}
                    />
                {sku && <datalist id="Articulo">
                            {sku.map(art => (
                                <option key={art} value={art}>
                                {art}
                                </option>
                            ))}
                        </datalist>}
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{ alignItems: 'center' }}>
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Transporte:</label>
                        <select
                            name="Transporte"
                            style={{maxWidth:'25rem'}}
                            className="form-control"
                            id="transporte"
                            onChange={handleForm1Change}

                        >
                            <option value="Correo Argentino">Correo Argentino</option>
                            <option value="Credifin">Credifin</option>
                            <option value="Retira">Retira</option>
                        </select>
                </div>
                {/* <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Falla:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = "{dato.Desperfecto}"
                        disabled={true}
                    />
                </div> */}
                <label style={{margin:'0 1rem',minWidth:'5rem', marginTop:'1rem'}}>Observaciones sobre el pedido:</label>
                <div className=' d-flex ms-auto mb-3' style={{alignItems: 'center', marginTop:'1rem' }} >
                    
                    <input 
                        className= "form-control"
                        name="Comentarios"
                        id="observaciones"
                        onChange={handleForm1Change}
                    />
                </div>
                {/* <label style={{margin:'0 1rem',minWidth:'5rem',marginTop:'1rem' }}>Repuestos ya solicitados para este documento:</label>
                <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center', marginTop:'1rem' }} > */}
                    
                    {/* <table className="table table-bordered">
                        <thead>
                            <tr>
                            <th>Fecha</th>
                            <th>Artículo</th>
                            <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dato.Repuestos.map((repuestos, index) => (
                            <tr key={index}>
                                <td>{repuestos.Fecha}</td>
                                <td>{repuestos.Articulo}</td>
                                <td>{repuestos.cantidad}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table> */}

                {/* </div> */}
            </div>
            <br/>
            <div style={{marginTop:'30px'}}>
                {listaprecios[0] && <Table showDelete={true} listaOrdenes={listaprecios[0]} source={"orden"} rows={listaprecios[0].length} settingcolumns={columnsRepuestosCF} globalfilter={true} statefilter={false} exporttable={false} showRequest={true} showQuantity={true} pedido={pedido} setPedido={setPedido}/>}
            </div>
            <button className="btn btn-secondary col-12" onClick={filtrado}>
                Cargar pedido
            </button>

            <div style={{marginTop:'30px'}}>
                <h5>
                    Pre-visualizacion de los repuestos a solicitar
                </h5>
                
                {/* {pedidofiltrado && <Table showDelete={true} listaOrdenes={pedidofiltrado} source={"orden"} settingcolumns={columnsRepuestosConfirmar} exporttable={true} showRequest={true}/>} */}
{pedidofiltrado && pedidofiltrado.length>0 && <> <table className="table table-striped table-hover " style={{textAlign: "center"}}>
                    <thead className="thead-light">
                        <tr>
                        <th>Fecha</th>
                        <th>Repuesto</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pedidofiltrado.map((fila, index) => (
                        <tr key={index} >
                            <td>{fila.Fecha}</td>
                            <td>{fila.Articulo}</td>
                            <td>{fila.Descripcion}</td>
                            <td>{fila.cantidad}</td>
                            <td>
                            <button onClick={() => handleEliminarRepuesto(fila)} style={{border: 'none' }} >
                               <img src={deleterow} alt="Eliminar" style={{ width: '35px', height: '35px', border: 'none' }}/>
                            </button>
                            </td>
                        </tr>
                        ))}  
                        
                    </tbody>          
                </table>
                <div className="d-flex justify-content-center ">
                        <button className="btn btn-success col-12 justify-content-center" onClick={AddSparepartsRequest}>
                            Confirmar
                        </button>
                </div>
               </>
                }
            </div>

           
            {/* {pedidofiltrado && <ol style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', margin: 0, padding: 0 }}>
                                {pedidofiltrado && Object.values(pedidofiltrado).map((r, i) => {
                                    return(
                                        <li key={`${r + i}`} style={{ padding: '0.5rem', marginRight: '0.5rem', marginBottom: '0.5rem' }}>
                                            
                                            <div style={{ display: 'flex', alignItems: 'center',  padding: '1rem', position: 'relative' }}>
                                                <pre style={{ marginRight: '0.5rem' }}>{JSON.stringify(r, null, 2)}</pre>
                                                    <button
                                                        type="button"
                                                        onClick={() => {EliminarRepuesto(r);}}
                                                        style={{
                                                        backgroundColor: 'red',
                                                        color: '#fff',
                                                        borderRadius: '50%',
                                                        border: 'none',
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        cursor: 'pointer'
                                                        }}
                                                    >
                                                    x
                                                    </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ol>} */}
        </div>
    )
}