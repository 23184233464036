import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [], // Aquí almacenarás los datos de productos
};

const productDataSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload; // Actualiza los datos de productos
    },
  },
});

export const { setProducts } = productDataSlice.actions;
export default productDataSlice.reducer;
