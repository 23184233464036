import React, { useRef, useState, useEffect } from 'react';
import { ExportTable } from './exportTable';
import { ExportRemsTable } from './exportRemsTable';

export const ModalRemito = ({listaOrdenes}) => {
   //console.log(listaOrdenes)
  const [tableReady, setTableReady] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    // Verificar si listaOrdenes tiene datos
    if (listaOrdenes && listaOrdenes.length > 0) {
      setTableReady(true);
    }
  }, [listaOrdenes]);




    const table = () => {
      return (
        <table ref={tableRef} style={{ display: 'none' }}>
          <thead>
            <tr>
             
              <th>Fecha</th>
              <th>N° Orden</th>
              <th>Tecnico</th>
              <th>Cliente</th>
              <th>Respuesto</th>
              <th>Descripcion</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Garantia</th>
              <th>Falla</th>
              <th>Transporte</th>
              <th>Orden Taller </th>
              <th>Lote Despachante</th>
              <th>Despachado</th>
              <th>Estado</th>
              {/* <th>TimeStamp</th> */}
                
            </tr>
          </thead>
          <tbody>
          {listaOrdenes.map((order, index) =>(
            <tr key={index}>
                <td>{order.Fecha}</td>
                <td>{order.iddoc}</td>
                <td>{order.Taller}</td>
                <td>{order.Cliente}</td>
                <td>{order.Articulo}</td>
                <td>{order.Descripcion}</td>  
                <td>{order.Producto}</td> 
                <td>{order.Cantidad}</td> 
                <td>{order.Garantia ? 'si' : 'no'}</td>
                <td>{order.Falla}</td> 
                <td>{order.Transporte}</td>
                <td>{order.OrdenTaller}</td> 
                <td>{order.Lote||'-'}</td>
                <td>{order.FDespachado||'-'}</td> 
                <td>{order. UltEstado}</td>
              
            </tr>
          ))}
          </tbody>
        </table>
      );
    };

    useEffect(() => {
      if (listaOrdenes) {
        setTableReady(true);
      }
    }, [listaOrdenes]);
    
  return (
    
    <>
      {table()}
      <div className="modal fade" id="modalTable" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirmar descarga
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p> ¿Deseas exportar la tabla a Excel?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
              {tableReady && <ExportRemsTable tablereferencia={tableRef} />} {/* Pasa la referencia completa */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

