import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

export const DeltaTimesChart = ({ deltaTimes, media, desviacionEstandar, mediana, Q1, Q3}) => {
  const data = {
    labels: deltaTimes.map((_, index) => index + 1),
    datasets: [
      {
        label: 'Delta Time',
        data: deltaTimes,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,
      },
      {
        label: 'Media',
        data: new Array(deltaTimes.length).fill(media),
        borderColor: 'rgba(255, 99, 132, 1)',
        borderDash: [10, 5],
        fill: false,
      },
      {
        label: 'Mediana',
        data: new Array(deltaTimes.length).fill(mediana),
        borderColor: 'rgba(255, 205, 86, 1)',
        borderDash: [10, 5],
        fill: false,
      },
      {
        label: '± Desviación Estándar',
        data: deltaTimes.map((_, index) => media + desviacionEstandar),
        borderColor: 'rgba(153, 102, 255, 1)',
        borderDash: [5, 5],
        fill: false,
      },
      {
        label: '± Desviación Estándar',
        data: deltaTimes.map((_, index) => media - desviacionEstandar),
        borderColor: 'rgba(153, 102, 255, 1)',
        borderDash: [5, 5],
        fill: false,
      },
      {
        label: 'Q1 (25º Percentil)',
        data: new Array(deltaTimes.length).fill(Q1),
        borderColor: 'rgba(255, 159, 64, 1)',
        borderDash: [5, 5],
        fill: false,
      },
      {
        label: 'Q3 (75º Percentil)',
        data: new Array(deltaTimes.length).fill(Q3),
        borderColor: 'rgba(255, 159, 64, 1)',
        borderDash: [5, 5],
        fill: false,
      },
    ],
  };

  return (
    <div>
      <h4>Ordenes del dia</h4>
      <Line data={data} />
    </div>
  );
};
