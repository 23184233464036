import React, {useState, useRef, useEffect} from 'react'
import { useReactToPrint } from 'react-to-print';
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import { useSelector } from 'react-redux';

export const OrdenModal = (documentInfo) =>{

    const [pages, setPages] = useState([0])
    const [indice, setIndice] = useState()
    const [document, setDocument] = useState({
        Fecha: '-',
        ID: '-',
        Titular: '-',
        Taller: '-',
        Direccion: '-',
        Telefono: '-',
        Email: '-',
        Cliente: '-',
        Domicilio_cli: '-',
        CuitDni_cli: '-',
        Telefono_cli: '-',
        Email_cli: '-',
        Categoria: '-',
        Articulo: '-',
        Tipo: '-',
        Desperfecto: '-',
        Desperfecto_declarado: '-',
        Comentarios: '-',
        Garantia: '-',
        NumFactura: '-',
        RazonSocialCli: '-',
        FechaCompra: '-',
        Repuestos: '-',
        Num_Serie: '-',
        Num_Os: '-',
        Localidad:'-',
        Provincia:'-',
        CodigoPostal: '-',
        Transporte: '-',
        piso: '-'
    })

    // const datos = {
    //     Fecha: documentInfo.data.Fecha,
    //     ID: documentInfo.data.iddoc,
    //     Titular: documentInfo.data.Titular,
    //     Taller: documentInfo.data.Taller,
    //     Direccion: documentInfo.data.Direccion,
    //     Telefono: documentInfo.data.Telefono,
    //     Email: documentInfo.data.Email,
    //     Cliente: documentInfo.data.Cliente,
    //     Domicilio_cli: documentInfo.data.Domicilio_cli,
    //     CuitDni_cli: documentInfo.data.CuitDni_cli,
    //     Telefono_cli: documentInfo.data.Telefono_cli,
    //     Email_cli: documentInfo.data.Email_cli,
    //     Categoria: documentInfo.data.Categoria,
    //     Articulo: documentInfo.data.Articulo,
    //     Descripcion: documentInfo.data.Descripcion,
    //     Desperfecto: documentInfo.data.Desperfecto,
    //     Desperfecto_declarado: documentInfo.data.Desperfecto_declarado,
    //     Comentarios: documentInfo.data.Comentarios,
    //     Garantia: documentInfo.data.Garantia,
    //     NumFactura: documentInfo.data.NumFactura,
    //     RazonSocialCli: documentInfo.data.RazonSocialCli,
    //     FechaCompra: documentInfo.data.FechaCompra,
    //     Repuestos: documentInfo.data.Repuestos || [],
    //     Num_Serie: documentInfo.data.Num_Serie,
    //     Num_Os: documentInfo.data.Num_Os,
    //     Localidad:documentInfo.data.Localidad_cli,
    //     Provincia:documentInfo.data.Provincia_cli,
    //     CodigoPostal: documentInfo.data.CodigoPostal_cli
    // }

    const orders = useSelector(state => state.ordersInfo)
    const ordenes_modificadas = orders.filter((item) => item.Modificaciones && item.iddoc === documentInfo.data.iddoc)
    const originalOrder = orders.filter((item) => item.iddoc === documentInfo.data.iddoc)

    const handleClick = (index) => {
        
        let fuenteDatos;
        if (index === 0) {
            fuenteDatos = ordenes_modificadas[0].Modificaciones[index]
            setIndice(index)
        } else if (index === ordenes_modificadas[0].Modificaciones.length) {
            fuenteDatos = originalOrder[0];
            setIndice(index)
        } else {
            fuenteDatos = ordenes_modificadas[0].Modificaciones[index];    
            setIndice(index)
        }
        const datos = {
            Fecha: fuenteDatos.Fecha,
            ID: fuenteDatos.iddoc,
            Titular: fuenteDatos.Titular,
            Taller: fuenteDatos.Taller,
            Direccion: fuenteDatos.Direccion,
            Telefono: fuenteDatos.Telefono,
            Email: fuenteDatos.Email,
            Cliente: fuenteDatos.Cliente,
            Domicilio_cli: fuenteDatos.Domicilio_cli,
            CuitDni_cli: fuenteDatos.CuitDni_cli,
            Telefono_cli: fuenteDatos.Telefono_cli,
            Email_cli: fuenteDatos.Email_cli,
            Categoria: fuenteDatos.Categoria,
            Articulo: fuenteDatos.Articulo,
            Tipo: fuenteDatos.Tipo,
            Desperfecto: fuenteDatos.Desperfecto,
            Desperfecto_declarado: fuenteDatos.Desperfecto_declarado,
            Comentarios: fuenteDatos.Comentarios,
            Garantia: fuenteDatos.Garantia,
            NumFactura: fuenteDatos.NumFactura,
            RazonSocialCli: fuenteDatos.RazonSocialCli,
            FechaCompra: fuenteDatos.FechaCompra,
            Repuestos: fuenteDatos.Repuestos || [],
            MotivoCierre: fuenteDatos.MotivoCierre,
            Num_Serie: fuenteDatos.Num_Serie,
            Num_Os: fuenteDatos.Num_Os,
            Localidad_cli: fuenteDatos.Localidad_cli,
            Provincia_cli: fuenteDatos.Provincia_cli,
            CodigoPostal_cli: fuenteDatos.CodigoPostal_cli,
            Transporte_cli: fuenteDatos.Transporte_cli,
            PisoDept_cli: fuenteDatos.PisoDept_cli,
            FechaModificacion: fuenteDatos.FechaModificacion,
            
        };

        setDocument(datos);
        
    };

    useEffect(() =>{
        if (documentInfo.data.Id && documentInfo.data.FechaModificacion){
            const modificacionesLength = ordenes_modificadas[0].Modificaciones.length;

            // Generar un array con un rango desde 1 hasta modificacionesLength
            const rangeArray = Array.from({ length: modificacionesLength }, (_, index) => index + 1);
            setPages([...pages, ...rangeArray])
        }
        setDocument(documentInfo.data)
    },[documentInfo.data])

    const OrderRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
        pageStyle: `
        @page {
            size: 21cm 29.5cm; /* A4 landscape */
            margin: 0.5cm; /* Margen de 1cm en todos los lados */
        }`,
        },
    );


    const closeModal = () => {
        const modal = document.getElementById('Modal');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
    };

    const handleCloseModal = () => {
        closeModal();
    };

    window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);

    const getPageMargins = () => {
        return `@page { margin: ${'1cm'} ${'1cm'} ${'1cm'} ${'1cm'} !important; }`;
      };

    return(
    <div className="modal"
                id="Modal"
                tabIndex={-1}
                aria-labelledby="ModalLabel"
                aria-hidden="true"
                style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}} ref={OrderRef}>
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg">

        <div className='container modal-content' style={{overflowX: "auto"}} id='closemodal' >
        <style>{getPageMargins()}</style>
            <div className='row' style={{marginTop:'15px'}}>
                    <div className='col-6 d-flex justify-content-start'>
                        <img src={ServicesPostventaLogo} width="210" height="60"></img>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        {/* <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button> */}
                        {documentInfo.data.FechaModificacion &&
                            <p className='d-flex justify-content-start'>{indice === 0?'Documento original.':`Modificado el: ${document.FechaModificacion}`}</p>
                        }
                    </div>
            </div>
            <div className="row">
                <p className='d-flex justify-content-end'>Fecha: {document.Fecha}</p>
            </div>
            {/* Titulo y ID documento */}
            <div className="row" style={{marginBottom:'15px'}}>
                <div className='col-6 d-flex justify-content-start'>

                    <h3>Orden de servicio</h3>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <p>ID: {document.ID || document.iddoc}</p>
                </div>
            </div>
            {/* Datos taller y boton imprimir */}
            <div style={{borderBottom: '1px solid', width:'100%',marginBottom:'20px', borderBottomColor:'#bcbcbc'}}>
                <div className="row">
                    <p className='col-lg-6 d-flex mb-1'>Responsable: {document.Titular}</p>
                    <p className='col-lg-6 d-flex mb-1'>Razon social: {document.Taller}</p>
                    <p className='col-lg-6 d-flex mb-1'>Domicilio: {document.Direccion}</p>
                    <p className='col-lg-6 d-flex mb-1'>Telefono: {document.Telefono}</p>
                    <p className='col-lg-6 d-flex mb-1'>E-mail: {document.Email}</p>
                </div>
                <div className="row" style={{marginBottom:'20px'}}>
                    <div className='col-12 d-flex justify-content-end d-print-none'>
                        <button style={{border:'none',backgroundColor: '#ffffff'}} onClick={()=>{handlePrint()}}>
                            <label style={{margin:'0 20px'}}>Imprimir</label>
                            <img src={impresora} width='50rem' height='50rem' alt=''/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-12 d-flex justify-content-start' style={{marginBottom:'20px'}}>
                    <h4>Datos del cliente:</h4>
                </div>
            
                <form style={{marginBottom:'20px'}}>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                        
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Nombre:</label>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>{document.Cliente}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Domicilio:</label>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>{document.Domicilio_cli}</label>
                            </div>
                   
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Cuit/Dni:</label>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>{document.CuitDni_cli}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Telefono:</label>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>{document.Telefono_cli}</label>
                            </div>
                                          
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>E-mail:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.Email_cli}</label>
                            </div>
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Localidad:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.Localidad_cli}</label>
                               
                            </div>

                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Provincia:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.Provincia_cli}</label>
                               
                            </div>
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Codigo postal:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.CodigoPostal_cli}</label>
                               
                            </div>
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Transporte:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.Transporte_cli}</label>
                               
                            </div>
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Piso/Dto:</label>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.PisoDept_cli}</label>
                               
                            </div>
                    </div>           
                </form>
            </div>
            <div className="row">
                <div className='col-12 d-flex justify-content-start' style={{marginBottom:'10px'}}>
                    <h4>Datos del producto:</h4>
                </div>
                <div className='col-lg-6 d-flex ms-1 mb-4' style={{alignItems: 'center' }}>
                    <label className="fw-bold" style={{margin:'0 0.2rem'}}>En garantía:</label>
                    <label style={{margin:'0 0.2rem'}}>{document.Garantia?'SI':'NO'}</label>
                </div>
                
         {document.Garantia && <div className="row"> 
                                <div className='col-lg-12 d-flex ms-auto mb-3'>
                                    <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Numero de factura:</label>
                                    <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.NumFactura}</label>
                                </div>
                                <div className='col-lg-6 d-flex ms-auto mb-3'>
                                    <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Razon Social:</label>
                                    <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.RazonSocialCli}</label>
                                </div>
                                <div className='col-lg-6 d-flex ms-auto mb-3'>
                                    <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Fecha:</label>
                                    <label style={{margin:'0 1rem', minWidth:'5rem'}}>{document.FechaCompra}</label>
                                </div>
                            </div>
                            }
                    
                <form style={{marginBottom:'20px'}}>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                        
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Categoria:</label>
                                <label style={{minWidth:'5rem'}}>{document.Categoria}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Articulo:</label>
                                <label style={{minWidth:'5rem'}}>{document.Articulo}</label>
                            </div>
                   
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Descripcion:</label>
                                <label style={{minWidth:'5rem'}}>{document.Descripcion}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Numero de serie:</label>
                                <label style={{minWidth:'5rem'}}>{document.Num_Serie}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Numero de orden:</label>
                                <label style={{minWidth:'5rem'}}>{document.Num_Os}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Mtv. devolucion:</label>
                                <label style={{minWidth:'5rem'}}>{document.Desperfecto}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Falla declarada:</label>
                                <label style={{minWidth:'5rem'}}>{document.Desperfecto_declarado}</label>
                            </div>
                                        
                            <div className='col-lg-12 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Observacion:</label>
                                <label style={{minWidth:'5rem'}}>{document.Comentarios}</label>
                            </div>
                    </div>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                            <h4>Repuestos:</h4>
                            <label style={{margin:'0 1rem',minWidth:'5rem', marginBottom:'20px'}}>Repuestos solicitados:</label>
                            <div className='col-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <ul>
                                {document.Repuestos && Array.isArray(document.Repuestos) ? (document.Repuestos.map((repuesto, index) => (
                                <li key={index}>{`${repuesto.Articulo} (${repuesto.Descripcion})`}</li>
                                ))
                                ) : (
                                    <li>No hay repuestos solicitados</li>
                                    )}
                            </ul>
                            </div>
                    </div>              
                </form>
                {/* <div className="row">*/}
                    <div className='row d-flex justify-content-start d-print-none'style={{marginBottom:'20px', alignItems: 'center'}}>
                        <h4>Datos del servicio:</h4>
                        <label className="fw-bold" style={{margin:'0 1rem', marginBottom:'10px'}}>{document.Estado}</label>
                        <div className=' col-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                           
                            <label style={{margin:'0 1rem'}}>
                                {Array.isArray(document.MotivoCierre) ? (
                                    document.MotivoCierre.map((motivo, index) => (
                                    <li key={index}>{motivo}</li>
                                    ))
                                ) : (
                                    <li>No hay motivos de cierre disponibles</li>
                                )}
                            </label>
                        </div>
                        {document.FechaModificacion && pages &&
                            <div className="row" style={{marginTop:'15px'}}>
                                <nav aria-label="...">
                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-end">
                                            <p>Versiones:</p>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start">
                                            <ul className="pagination pagination-sm d-flex justify-content-start">
                                            
                                                {pages.map((page, index) => (
                                                    <li className="page-item" key={index}>
                                                        <button className="page-link" onClick={() => handleClick(index)}>
                                                            {page}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                                <p className='d-flex justify-content-end'>{indice === 0?'Documento original.':`Modificado el: ${document.FechaModificacion}`}</p>
                            </div>
                        }
                    </div> 
                     {/* <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar fotos:    
                                <img src={camara} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                    </div>
                    <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto",marginBottom:'20px' }}>
                       
                        <table className="table table-hover table-responsive-sm table-bordered" ref={refrepuestos}>
                            <thead className = 'table-dark'>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Repuesto</th>
                                    <th>Descripcion</th>
                                    <th>Cantidad</th>
                                    <th>Falla</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: '5rem'}}>{date1}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption1} onChange={handleTypeSelect1} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column1'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion1}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date2}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption2} onChange={handleTypeSelect2} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column2'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion2}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem'}}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date3}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption3} onChange={handleTypeSelect3} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column3'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion3}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date4}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption4} onChange={handleTypeSelect4} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column3'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion4}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    
                    {/* <div className='col-12 d-flex justify-content-center d-print-none' style={{marginBottom:'20px'}}>
                        <button type="submit" onClick={handleFormSubmit} className='col-12' >Guardar</button>    
                    </div>
                    <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar conformidad:    
                                <img src={verificacion} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                    </div>
                    <div className='col-6 d-none d-print-block' style={{marginTop:'100px'}}>
                        <label className='d-flex justify-content-center'>......................................................................</label>
                        <label className='d-flex justify-content-center'>Firma</label>
                    </div> 
                </div> */}
            </div>
        </div>
    </div>
    </div>
    )
}