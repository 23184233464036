import React from 'react'
import { useDownloadExcel } from 'react-export-table-to-excel';



export const ExportRemsTable = ({ tablereferencia }) => { // Ajusta la destructuración de props
    
    const { onDownload } = useDownloadExcel({
      currentTableRef: tablereferencia.current,
      filename: "Lista de Ordenes",
      sheet: "Ordenes"
    });
  
    // alerta de confirmacion
    const exportConfirmation = () => {
      if (window.confirm("Confirmar exportacion a excel")) {
        onDownload();
      }
    };
    
    return (
        <div>

            <button
                className='btn btn-success' 
                type='button'
                 onClick={exportConfirmation}
               >
               Descargar
            </button>
            
        </div>
    )
}