import React, { useState, useEffect, useRef } from 'react';
import {Html5Qrcode} from 'html5-qrcode';
import {useNavigate} from "react-router-dom";

export const QRScanner = () => {

  const navigate = useNavigate();

  const [scanResult, setScanResult] = useState(null);
  const [scanError, setScanError] = useState(null);

  const scannerContainerRef = useRef("r");
  const html5QrCodeRef = useRef(null);
  

  useEffect(() => {

    let html5QrCode = null

    const startScanner = async () => {
      const containerElement = scannerContainerRef.current;
      
      try {
        if(containerElement.id === "r"){

          html5QrCode = new Html5Qrcode("r");

          const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            setScanResult(decodedText)
            html5QrCode.stop().catch((err) => {
              console.error('Error al detener el escaneo:', err);
            });
          };
          
          const config = {
                            qrbox: {
                              width: 250,
                              height: 250,
                            },
                            fps: 5,
                          }

          await html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback)
           
        }
      } catch (error) {
        console.error('Error al iniciar el escáner:', error);
      }
    };

    const stopScanner = () => {
      if (html5QrCode != null){
        html5QrCode.stop().catch((err) => {
        console.error('Error al detener el escaneo:', err);
      });
      }
    };

    startScanner();

    // Detener el escaneo después de 30 segundos
    const scanTimeout = setTimeout(() => {
      stopScanner();
      setScanError('Escaneo finalizado por tiempo agotado.');
      // navigate('/nuevaorden')
    }, 30000);

    return () => {
      // stopScanner();
      clearTimeout(scanTimeout);
    };
  }, []);

  useEffect(() => {
    if (scanResult) {
      navigate(scanResult); // Navegar al enlace proporcionado por scanResult
    }
  }, [scanResult]);
  
  return (
    <div>
      <h1>QR Code Scanning</h1>
      <div ref={scannerContainerRef} id="r" style={{
                                                    width: '100%',  // Ancho del contenedor (puede ser un valor específico en píxeles o porcentaje)
                                                    maxWidth: '20rem',  // Ancho máximo para evitar que sea demasiado grande
                                                    margin: '0 auto',  // Centrar el contenedor horizontalmente
                                                  }}>
      </div>
      {scanResult ? (
        <div>
          <a href={scanResult}> Ver orden</a>
        </div>
      ) : scanError ? (
        <div>
          <p>Error: {scanError}</p>
        </div>
      ) :''}
    </div>
  );
};

