import axios from "axios";

export const SlackErrorHandle = async (ErrorMsj) =>{

    const webHookURL = process.env.REACT_APP_SLACKERRORMSJ
    const data = {text : ErrorMsj}
    
    try{
    const slackSend = await axios.post(webHookURL, JSON.stringify(data),{
        withCredentials:false,
        timeout: 10000,
    })
        
    }catch(error){
        console.log(error)
    };

}