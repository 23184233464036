import React, {useState, useEffect} from 'react'
import {Table} from './table'
import {columnsTalleres} from './columns';
import {NewTaller, GetTalleres} from './dbfirebase'
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'
import { useAuth } from "../context/authcontext";

export const ListaTalleres = () => {

    const {user} = useAuth();
    const [dato, setDato] = useState()
    const [taller, setTaller] = useState({
        AccessUUID:'-',
        Titular:'',
        Taller:'',
        Cuit:'',
        Telefono:'',
        Direccion:'-',
        Email:'',
        Transporte:'',
        Permiso:'Tecnico',
        App: 'Service'
    })
    const [place, setPlace] = useState('')

    const data = async() =>{ 
        const talleres = await GetTalleres()
        setDato(talleres)
    }

    const ClearForm = () => { 
        document.getElementById("Form").reset();
    }

    useEffect(()=>{
        data()
    },[])

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setTaller((prevState) => ({ ...prevState, [name]: value }));
    };

    // const handleFormSubmit = async() => {
    //     if(place === ''){
    //         const upload = await NewTaller(taller)
    //         ClearForm()
    //         data()
    //     } 
    // }

    const handleFormSubmit = async() => {
        if (Object.keys(taller).some(key => taller[key] === "") || place === '') {
            
            toast.error('Debe completar todos los campos de usuario',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })
           
        }else{
            
            const upload = await NewTaller(taller)
            toast.success('Datos cargados con exito',{
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true})
            ClearForm()
            data()
        }           
    }
    
    return(
        <div>
            <div className="row">
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Talleres</h3>
                <Ayuda section="listaDeTalleres"/>
                <ToastContainer/>
            </div>


            
            <div className='card' id='registro-taller' style={{marginTop:'50px'}}>
                
            <h4 className='d-flex justify-content-center' style={{margin:'50px'}}>Nuevo taller</h4>

                <form id='Form'  style={{marginLeft:'75px',alignContent: 'center'}}>
                    <div className="row " style={{alignContent: 'center', margin:"25px" }}>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Titular:</label>
                                <input 
                                    type = "text"
                                    name = "Titular"
                                    className = "form-control"
                                    placeholder = "Titular"
                                    id = "Titular"
                                    autoFocus
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Direccion:</label>
                                <Autocomplete
                                    name = "Direccion"
                                    className = "form-control"
                                    placeholder = "Direccion"
                                    id = "direccion"
                                    //onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    apiKey= {process.env.REACT_APP_MAP}
                                    onPlaceSelected={(place, autocomplete) => {
                                         // Obtén la dirección del lugar seleccionado y actualiza el estado 'taller'
                                        const addressComponents = place.address_components || [];
                                        let direccion = '';
                                        let localidad = '-';
                                        let provincia = '-';

                                        addressComponents.forEach((component) => {
                                            if (component.types.includes('street_number') || component.types.includes('route')) {
                                                direccion += component.long_name + ' ';
                                            }
                                            if (component.types.includes('locality')) {
                                                localidad = component.long_name;
                                            }
                                            if (component.types.includes('administrative_area_level_1')) {
                                                provincia = component.long_name;
                                            }
                                            // Puedes agregar más lógica aquí para otros componentes de dirección si es necesario
                                        });

                                        // Actualiza el estado 'taller'
                                        setTaller((prevTaller) => ({
                                            ...prevTaller,
                                            Direccion: direccion.trim(),
                                            Localidad: localidad,
                                            Provincia: provincia,
                                        }));
                                        setPlace(place)
                                    }}
                                    options={{
                                        types: ['address'],
                                        componentRestrictions: { country: 'ar' },
                                    }}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Razon social:</label>
                                <input 
                                    type = "text"
                                    name = "Taller"
                                    className = "form-control"
                                    placeholder = "Razon social"
                                    id = "Taller"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Localidad:</label>
                                <input 
                                    type = "text"
                                    name = "Localidad"
                                    className = "form-control"
                                    placeholder = "Localidad"
                                    id = "Localidad"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    defaultValue={place && place.address_components && place.address_components[2] && place.address_components[2].long_name ? place.address_components[2].long_name : ''}
                                    disabled = {true}
                                    />  
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit:</label>
                                <input 
                                    type = "text"
                                    name = "Cuit"
                                    className = "form-control"
                                    placeholder = "Cuit"
                                    id = "Cuit"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Provincia:</label>
                                <input 
                                    type = "text"
                                    name = "Provincia"
                                    className = "form-control"
                                    placeholder = "Provincia"
                                    id = "Provincia"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    defaultValue={place && place.address_components && place.address_components[4] && place.address_components[4].long_name ? place.address_components[4].long_name : ''}
                                    disabled = {true}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                <input 
                                    type = "text"
                                    name = "Telefono"
                                    className = "form-control"
                                    placeholder = "Telefono"
                                    id = "Telefono"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Mail:</label>
                                <input 
                                    type = "email"
                                    name = "Email"
                                    className = "form-control"
                                    placeholder = "Mail"
                                    id = "Email"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Transporte:</label>
                                <input 
                                    type = "text"
                                    name = "Transporte"
                                    className = "form-control"
                                    placeholder = "Transporte"
                                    id = "Transporte"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Codigo postal:</label>
                                <input 
                                    type = "text"
                                    name = "CodigoPostal"
                                    className = "form-control"
                                    placeholder = "Codigo Postal"
                                    id = "CodigoPostal"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Vacaciones:</label>
                                <input 
                                    type = "text"
                                    name = "Vacaciones"
                                    className = "form-control"
                                    placeholder = "Vacaciones"
                                    id = "vacaciones"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Horarios:</label>
                                <input 
                                    type = "text"
                                    name = "Horarios"
                                    className = "form-control"
                                    placeholder = "Horarios"
                                    id = "horarios"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Codigo de SAP</label>
                                <input 
                                    type = "text"
                                    name = "CodigoSAP"
                                    className = "form-control"
                                    placeholder = "Codigo de registro en SAP"
                                    id = "codigoSAP"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
{/* {user.uid==='XomEwrWKnGeIc0rZXaoZNKM28Hm2'?
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nivel Acceso:</label>
                                <input 
                                    type = "text"
                                    name = "App"
                                    className = "form-control"
                                    placeholder = "Nivel Acceso: 'GOD','Service','Administracion'"
                                    id = "app"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>:''}
{user.uid==='XomEwrWKnGeIc0rZXaoZNKM28Hm2'?                      
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Permiso:</label>
                                <input 
                                    type = "text"
                                    name = "Permiso"
                                    className = "form-control"
                                    placeholder = "Nivel Permiso: 'Tecnico','Admin'"
                                    id = "permiso"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>:''} */}
                    </div>    
                </form>
                
                <div className='d-flex justify-content-center' style={{margin:'50px'}}>
                    <button type="submit" onClick={handleFormSubmit} className='btn btn-success w-25'>Guardar</button>    
                </div>
            </div>
                
            <div className= 'row d-flex justify-content-center' id="tabla-taller">
                {dato && <Table listaOrdenes={dato} exporttable={true} filter={true}source={"orden"} settingcolumns={columnsTalleres} rows={20}/>}
            </div>
        </div>
    )
}