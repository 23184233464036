import React from 'react'
import { useBarcode } from 'next-barcode';


export const Barcode = ({value}) => {
    console.log(value)
    const { inputRef } = useBarcode({
        value: value,
        options: {
            height:50,
            displayValue: true,
            background: 'white'//'#ffff00',
        }
      });

    return(
        <div className='d-flex justify-content-center align-items-center'>
            <img ref={inputRef} style={{width:'inherit', height:'6rem'}}/>
        </div>
    )

}