import { useDownloadExcel } from 'react-export-table-to-excel';
import excel from '../images/excel.png'


export const ExportTable = (tablereference) => {
    
    const { onDownload } = useDownloadExcel({
        currentTableRef: tablereference.tablereference.current,
        filename: "Documento",
        sheet: "Hoja1"
    });
    
    // alerta de confirmacion
    const exportConfirmation = () => {
        if (window.confirm("Confirmar exportacion a excel")) {
            onDownload()
        }
    }

    
    return (
        <div>

            <button
                className='btn btn-outline-secondary btn-sm h-100 ms-2' 
                type='button'
                onClick={exportConfirmation}
                style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                <img src={excel} width="50rem" height="50rem"/>
            </button>
            
        </div>
    )
}