import React, { useState } from 'react';
import help from '../images/help.png'
import { useNavigate } from 'react-router-dom';

export const Ayuda = (section) => {
  
  const navigate = useNavigate()

  const navegarAComponenteDeAyuda = () => {
    // Navega a la ruta deseada cuando se hace clic en el botón
    navigate(`/manual#${section.section}`);
  };

  return (
    <div className="col">
      <button onClick={navegarAComponenteDeAyuda} style={{
        background: 'none',
        border: 'none',
        // padding: '0',
        cursor: 'pointer',
      }}
      >
        <img
          src={help} // Reemplaza con la URL de tu icono de ayuda
          alt="Icono de Ayuda"
          style={{ width: '24px', height: '24px' }}
        />
      </button>
    </div>
  );
}
