import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categorias: [], // Aquí almacenarás los datos de productos
};

const categoriasDataSlice = createSlice({
  name: 'categoriaData',
  initialState,
  reducers: {
    setCategorias: (state, action) => {
       
      state.categorias = action.payload; // Actualiza los datos de productos
    },
  },
});

export const { setCategorias } = categoriasDataSlice.actions;
export default categoriasDataSlice.reducer;