import { createSlice } from '@reduxjs/toolkit'


export const retailInfoSlice = createSlice({

    name: 'retailInfo',
    initialState: [],
    reducers: {
        addRetail: (state, action) => {
            return action.payload
        },
    }
})
export const { addRetail } = retailInfoSlice.actions
export default retailInfoSlice.reducer


