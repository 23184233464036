import {useAuth} from "../context/authcontext";
import {Navigate} from "react-router-dom";

export function ProtectedRoute({children}) {
    const {user, loading} = useAuth(null);

    if (loading) return <h1>loading</h1>;
    if (!user) return <Navigate to = "/" />;

    return <>{children}</>;
}