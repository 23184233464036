import React, {useEffect, useRef, useState} from 'react'
import { useMatch } from 'react-router-dom'
import {collection, doc, getDoc} from 'firebase/firestore';
import {db} from "../components/firebase";
import { ToastContainer, toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import impresora from '../images/impresora.png'
import {GetRepuestos} from './dbfirebase'

export const EditOrder = () =>{

    const [isChecked2, setIsChecked2] = useState(true);
    const [repuestos, setRepuestos] = useState([])
    const [repuest, setRepuest] = useState('')
    const [datos, setDatos] = useState({
            Fecha: '',
            ID: '',
            Titular: '',
            Taller: '',
            Direccion: '',
            Telefono:'',
            Email: '',
            Cliente: '',
            Domicilio_cli: '',
            CuitDni_cli: '',
            Telefono_cli: '',
            Email_cli: '',
            Categoria: '',
            Articulo: '',
            Descripcion: '',
            Desperfecto: '',
            Comentarios: '',
            Garantia: '',
            NumFactura: '',
            RazonSocialCli: '',
            FechaCompra: '',
            Oper_Alta_repuesto:true,
            Repuestos: '' || []
    })

    const match = useMatch('/ordenes/:id')
    const id = match.params.id

    const OrderRef = useRef(null)

    const GetOrders = async (id) => {
        try {
          const docRef = doc(db, 'OrdenesTaller', id);
      
          const querySnapshot = await getDoc(docRef);
          if (querySnapshot.exists()) {
            const docData = querySnapshot.data();
            setDatos(docData)
          } else {
            return([]);
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
    };

    useEffect(()=>{
        GetOrders(id)
    },[id])

    const handleForm1Change = (e) => {
       
    };

    const handleForm2Change = (e) => {
       
    };
    
    const handleCheckboxChange2 = (e) => {
        setIsChecked2(e.target.checked);
        setDatos((prevState) => ({ ...prevState, ['Oper_Alta_repuesto']: e.target.checked }))
    }

    const Repuestos = async() => {
        const Articulos = await GetRepuestos(datos.Categoria, datos.Articulo)
        setRepuest(Articulos)
    }

    useEffect(()=>{
        if (datos.Categoria){
            Repuestos()
        }
    },[datos.Articulo])

    const handleAddRepuesto = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newRepuesto = e.target.value;
            setRepuestos([...repuestos, newRepuesto]);
            e.target.value = '';
        }
    }; 

    const EliminarRepuesto = (r) => {
        const index = repuestos.indexOf(r);
        if (index !== -1) {
            const newRepuestos = [...repuestos];
            newRepuestos.splice(index, 1);
            setRepuestos(newRepuestos);
        }
    }

    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
    },
    );
    
    const getPageMargins = () => {
        return `@page { margin: ${'1rem'} ${'1rem'} ${'1rem'} ${'1rem'} !important; }`;
    };

    const operacion = [{
        'Segunda A':'Producto OK, cambiar caja',
        'Segunda B':'Producto OK, rayado o golpeado',
        'RECHAZADO':'Producto rechazado',
        'Producto 1ra':'Producto de primera',
        'Baja':'Producto para baja',
        'Deposito 5':'Producto a deposito 5'
    }]

    
    return(
        
        <div style={{marginBottom:'20px' ,marginTop:'20px'}} ref={OrderRef}>
        <style>{getPageMargins()}</style>
        <ToastContainer/>
        <div className='container' >
           
            <p className='d-flex justify-content-end'>Fecha: {new Date().toLocaleString().split(',')[0]}</p>
            {/* Titulo y ID documento */}
            <div className="row" style={{marginBottom:'15px'}}>
                <div className='col-6 d-flex justify-content-start'>
                    <h2>Orden de trabajo</h2>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <p>ID: {datos.iddoc? datos.iddoc:'Documento sin registrar'}</p>
                </div>
            </div>
            {/* Datos taller y boton imprimir */}
            <div style={{borderBottom: '1px solid', width:'100%',marginBottom:'20px', borderBottomColor:'#bcbcbc'}}>
                <div className="row">
                    {datos && <p className='col-lg-6 d-flex mb-1'>Responsable: {datos.Titular}</p>}
                    {datos && <p className='col-lg-6 d-flex mb-1'>Razon social: {datos.Taller}</p>}
                    {datos && <p className='col-lg-6 d-flex mb-1'>Domicilio: {datos.Direccion}</p>}
                    {datos && <p className='col-lg-6 d-flex mb-1'>Telefono: {datos.Telefono}</p>}
                    {datos && <p className='col-lg-6 d-flex mb-1'>E-mail: {datos.Email}</p>}
                    {datos && <p className='col-lg-6 d-flex mb-1'>Cuit: {datos.Cuit}</p>}
                </div>
                <div className="row" style={{marginBottom:'20px'}}>
                    <div className='col-12 d-flex justify-content-end d-print-none'>
                    <button style={{border:'none',backgroundColor: '#ffffff'}} onClick={()=>{handlePrint()}}>
                            <label style={{margin:'0 20px'}}>Imprimir</label>
                            <img src={impresora} width='50rem' height='50rem' alt=''/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-12 d-flex justify-content-start' style={{marginBottom:'20px'}}>
                    <h4>Datos del cliente:</h4>
                </div>
            
                <form style={{marginBottom:'20px'}} id="Form1">
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Nombre:</label>
                                <label style={{maxWidth:'25rem'}}> {datos.Cliente} </label>
                                {/* <input 
                                    type = "text"
                                    name = "fantacia"
                                    className = "form-control"
                                    placeholder = "Nombre de Fantasia"
                                    id = "fantasia"
                                    list='Fantasia'
                                    autoComplete = "off"
                                    onChange={handleForm1Change}
                                    style={{maxWidth:'25rem'}}
                                    disabled={true}//
                                    value={datos.Cliente}
                                    /> */}
                            </div>

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Cuit/Dni:</label>
                                <label style={{maxWidth:'25rem'}}> {datos.CuitDni_cli}</label>
                                {/* <input 
                                    type = "number"
                                    name = "CuitDni_cli"
                                    className = "form-control"
                                    placeholder = "Sin guiones ni puntos"
                                    id = "cuitdni"
                                    autoFocus
                                    style={{maxWidth:'25rem'}}
                                    disabled={true}
                                    value={datos.CuitDni_cli}
                                    /> */}
                            </div>
                            
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Domicilio:</label>
                                <label style={{maxWidth:'25rem'}}> {datos.Domicilio_cli}</label>
                                {/* <AddressAutofill accessToken=""
                                                options={{
                                                    language: 'es',
                                                    country: 'AR',
                                                }}> */}
                                    {/* <input 
                                        type = "text"
                                        name = "Domicilio_cli"
                                        //onChange = {handlechange} 
                                        className = "form-control"
                                        placeholder = "Calle, localidad, provincia"
                                        id = "domicilio"
                                        onChange={handleForm1Change}
                                        style={{maxWidth:'25rem'}}
                                        disabled={true}
                                        autoComplete="shipping address-line1"
                                        value={datos.Domicilio_cli}
                                        /> */}
                                {/* </AddressAutofill> */}
                                {/* <div>
                                <AddressMinimap show={true} query={cliente.Domicilio_cli}>
                                </AddressMinimap>
                                </div> */}
                            </div>

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{ minWidth:'5rem'}}>Razon Social:</label>
                                <label style={{maxWidth:'25rem'}}> {datos.RazonSocialCli}</label>
                                {/* <input 
                                    type = "text"
                                    name = "Cliente"
                                    className = "form-control"
                                    placeholder = "Razon social"
                                    id = "nombre"
                                    style={{maxWidth:'25rem'}}
                                    disabled={true}
                                    value={datos.RazonSocialCli}
                                    /> */}
                            </div>
                            
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Telefono:</label>
                                <label style={{maxWidth:'25rem'}}>{datos.Telefono_cli}</label>
                                {/* <input 
                                    type = "text"
                                    name = "Telefono_cli"
                                    className = "form-control"
                                    placeholder = "Sin guiones ni puntos"
                                    id = "telefono"
                                    style={{maxWidth:'25rem'}}
                                    disabled={true}
                                    value={datos.Telefono_cli}
                                    /> */}
                            </div>
                                          
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>E-mail:</label>
                                <label style={{maxWidth:'25rem'}}>{datos.Email_cli}</label>
                                {/* <input 
                                    type = "email"
                                    name = "Email_cli"
                                    className = "form-control"
                                    placeholder = "ejemplo@ejemplo.com.ar"
                                    id = "email"
                                    onChange={handleForm1Change}
                                    style={{maxWidth:'25rem'}}
                                    disabled={true}
                                    value={datos.Email_cli}
                                    /> */}
                            </div>

                            

                    </div>           
                </form>
            </div>
            <div className="row">
                <div className='col-6 d-flex justify-content-start'>
                    <h4>Datos del producto:</h4>
                </div>
                <div className='col-6 d-flex justify-content-center'>
                {!datos.Garantia? <h5>Nota de credito</h5>:<h5>Reparar y devolver</h5>}
                    
                </div>
                
                    {/* <div className='col-6 d-flex ms-1 mb-4 form-check form-switch' style={{alignItems: 'center' }}>
                        <label style={{margin:'0 1rem'}}>Procesar como RyD: </label>
                            <input className="form-check-input" 
                                    type="checkbox"
                                    role="switch"
                                    value=""
                                    name="Garantia"
                                    id="flexCheckDefault" 
                                    checked={datos.Garantia}
                                    disabled={true}
                                    style={{margin:'initial'}}/>
                    </div> */}
                    
            {datos.Garantia && <div className="row"> 
                            {/* <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>N° Remito:</label>
                                <input type = "text"
                                        name = "NumFactura"
                                        className = "form-control"
                                        placeholder = "Nº Factura"
                                        id = "NumFactura"
                                        onChange={handleForm2Change}
                                        style={{minWidth:'8rem'}}
                                        disabled={iddoc? true:false}
                                        />
                            </div> */}
                            <div className='col-lg-6 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>Razon Social:</label>
                                <label style={{minWidth:'8rem'}}>{datos.RazonSocialCli}</label>
                                {/* <input type = "text"
                                        name = "RazonSocialCli"
                                        className = "form-control"
                                        placeholder = "Razon Social"
                                        id = "RazonSocialCli"
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value = {datos.RazonSocialCli}
                                        /> */}
                            </div>
                            <div className='col-lg-6 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>Fecha:</label>
                                <label style={{minWidth:'8rem'}}>{datos.FechaCompra}</label>
                                {/* <input type = "date"
                                        name = "FechaCompra"
                                        className = "form-control"
                                        placeholder = "Fecha de compra"
                                        id = "FechaCompra"
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value = {datos.FechaCompra}
                                        /> */}
                            </div>
                        </div>
                        }
                    
                <form style={{marginBottom:'20px'}} id="Form2">
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc',marginTop:'20px' }}>

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor' >
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>N° Remito:</label>
                                <label style={{minWidth:'8rem'}}>{datos.NumRemito}</label>
                                {/* <input type = "text"
                                        name = "NumRemito"
                                        className = "form-control"
                                        placeholder = "Nº Remito"
                                        id = "NumRemito"
                                        list = "numRemito"
                                        autoComplete = "off"
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value = {datos.NumRemito}
                                        /> */}
                            </div>

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Articulo:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Articulo}</label>
                                {/* <input type = "text"
                                        name = "Articulo"
                                        //onChange = {handlechange} 
                                        className = "form-control"
                                        placeholder = "Articulo"
                                        id = "articulo"
                                        list="Articulo"
                                        autoComplete="off"
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value = {datos.Articulo}
                                        /> */}
                            </div>
                        
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Categoria:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Categoria}</label>
                                {/* <input type = "text"
                                        name = "Categoria"
                                        className = "form-control"
                                        placeholder = "Categorias"
                                        id = "categoria"
                                        list="Categoria"
                                        autoComplete="off"
                                        onChange={handleForm1Change}
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value={datos.Categoria}
                                        /> */}
                            </div>
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Tipo:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Tipo}</label>
                                {/* <input type = "text"
                                        name = "Tipo"
                                        className = "form-control"
                                        placeholder = "Tipo"
                                        id = "tipo"
                                        list="Tipo"
                                        autoComplete="off"
                                        style={{minWidth:'8rem'}}
                                        disabled={true}
                                        value={datos.Tipo}
                                        /> */}
                            </div>
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Descripcion:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Descripcion}</label>
                                {/* <input 
                                    type = "text"
                                    name = "Descripcion" 
                                    className = "form-control"
                                    placeholder = "Descripcion"
                                    id = "descripcion"
                                    style={{minWidth:'8rem'}}
                                    disabled={true}
                                    value={datos.Descripcion}
                                    /> */}
                            </div>
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Falla:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Desperfecto}</label>
                                {/* <input 
                                    type = "text"
                                    name = "Desperfecto"
                                    className = "form-control"
                                    placeholder = "Descripcion de la falla del producto"
                                    id = "falla"
                                    list='Falla'
                                    disabled={true}
                                    value={datos.Desperfecto}
                                    /> */}
                            </div>
                                          
                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label className="mb-2 mb-lg-0" style={{maxWidth:'5rem', minWidth:'5rem'}}>Observacion:</label>
                                <label style={{minWidth:'8rem'}}>{datos.Comentarios}</label>
                                {/* <input 
                                    type = "text"
                                    name = "Comentarios"
                                    className = "form-control"
                                    placeholder = "Estado general del producto. ej. Marcas, Sin accesorios, en caja original...etc."
                                    id = "observaciones"
                                    autoComplete="off"
                                    disabled={true}
                                    value={datos.Comentarios}
                                    /> */}
                            </div>

                            <div className='col-lg-6 d-flex flex-column mb-3 contenedor'>
                                <label style={{minWidth:'8rem'}}>{datos.Garantia?'Repuestos utilizados:': datos['Oper_Alta_repuesto']?'Repuestos recuperados':'Repuestos utilizados'}</label>
                                <input 
                                    type = "text"
                                    name = "Repuestos"
                                    className = "form-control"
                                    placeholder = {datos.Garantia?'Repuestos utilizados:':'Repuestos recuperados'}
                                    id = "repuestos"
                                    list = "rep"
                                    autoComplete="off"
                                    //onChange={handleForm3Change}
                                    onKeyPress={handleAddRepuesto}
                                    />
                                    <datalist id="rep">
                                        {repuest && repuest[0].repuestos.map((rep) => {
                                            const repuestoKey = Object.keys(rep)[0];
                                            const descripcion = rep[repuestoKey].Descripción;

                                            return (
                                            <option key={repuestoKey} value={repuestoKey}>
                                                {repuestoKey} - {descripcion}
                                            </option>
                                            );
                                        })}
                                    </datalist>
                            </div>
                            <div className='col-lg-3 d-flex ms-1 mb-4 form-check form-switch' style={{alignItems: 'center' }}>
                               <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>{datos['Oper_Alta_repuesto']?'Desarme de producto':'Recupero de producto'}</label>
                                <input className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    value=""
                                    name="Oper_Alta_repuesto"
                                    id="flexCheckDefault2" 
                                    checked={datos['Oper_Alta_repuesto']}
                                    onChange={handleCheckboxChange2}
                                    // disabled={!cliente.Garantia? false:true}
                                    style={{margin:'initial'}}/>
                            </div>
                            <div className='col-lg-3 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>Estado:</label>
                                <input 
                                    type = "text"
                                    name = "Estado"
                                    className = "form-control"
                                    placeholder = {datos.Estado || ''} //"Operacion de cierre"
                                    id = "Estado"
                                    list='estado'
                                    autoComplete="off"
                                    // disabled={!cliente.Articulo}
                                    // onChange={handleForm1Change}
                                    // disabled={limpiar? false:true}
                                    //onKeyPress={handleAddRepuesto}
                                />
                                    <datalist id="estado">
                                        {operacion.map((op) => (
                                            Object.keys(op).map((key) =>
                                            <option key={key} value={key}>
                                            {op[key]}    
                                            </option>)
                                        ))}
                                    </datalist>
                            </div>
                            <div className='col-lg-3 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem', backgroundColor: 'lightblue', minWidth:'5rem', fontSize: '1.5rem'}}>
                                    {datos.Garantia && datos.Estado == 'Segunda B'? 'Transferir a deposito 42':''}
                                    </label>
                            </div>
              {repuestos && <ol style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', margin: 0, padding: 0 }}>
                                {repuestos && Object.values(repuestos).map((r, i) => {
                                    return(
                                        <li key={`${r + i}`} style={{ padding: '0.5rem', marginRight: '0.5rem', marginBottom: '0.5rem' }}>
                                            
                                            <div style={{ display: 'flex', alignItems: 'center',  padding: '1rem', position: 'relative' }}>
                                                <span style={{ marginRight: '0.5rem' }}>{r}</span>
                                                    <button
                                                        type="button"
                                                        onClick={() => {EliminarRepuesto(r);}}
                                                        style={{
                                                        backgroundColor: 'red',
                                                        color: '#fff',
                                                        borderRadius: '50%',
                                                        border: 'none',
                                                        width: '1rem',
                                                        height: '1rem',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        cursor: 'pointer'
                                                        }}
                                                    >
                                                    x
                                                    </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ol>}
                    </div>           
                </form>
                <div className="row">
                    <div className='col-12 d-flex justify-content-start d-print-none'>
                        {/* <h4>Datos del servicio:</h4> */}
                    </div> 
                    
                    <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto",marginBottom:'20px' }}>
                        
                    
                    </div>
                     
                    <div className='col-12 d-flex justify-content-center d-print-none' style={{marginBottom:'20px'}}>
                        {/* <button type="submit" onClick={handleFormSubmit} className='col-12 btn btn-outline-success' disabled={limpiar? false:true} >Guardar</button>   */}
                       
                    </div>
                
                    <div className='row'>
                    <div className='col-6 d-none d-print-block' style={{marginTop:'140px'}}>
                        <label className='d-flex justify-content-center'>......................................................................</label>
                        <label className='d-flex justify-content-center'>Firma</label>
                    </div>
                    {/* QR para acceder a la app del cliente */}
                   <div className='col-6 d-none d-print-block' style={{marginTop:'20px'}}>
                            {/* <p className='d-flex justify-content-end' style={{margin:'0 0 0 0'}}>¡Hace el seguimiento desde la web!</p> */}
                            <div className='d-flex justify-content-center'style={{margin:'0 0 1rem 7rem'}} >
                                {/* <QRGenerator data={qrData}/> */}
                            </div>
                           <p className='d-flex justify-content-end'style={{margin:'0 0 0 0'}}>Orden:{datos.iddoc? datos.iddoc:'Documento sin registrar'}</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}