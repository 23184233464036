import React from 'react';
import QRCode from "react-qr-code";

const QRGenerator = ({ data }) => {
  return (
    <div className="col-6 d-flex justify-content-end">
        {/* <div className="col-12 d-flex justify-content-end mx-3">
            <h5>Código Generado:</h5>
        </div> */}
        <div className="col-12 d-flex justify-content-end">
            <QRCode value={data} style={{ height: "auto", maxWidth: "100%", width: "7.5rem" }}/>
        </div>
    </div>
  );
};

export default QRGenerator;
