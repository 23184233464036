import React, {useState, useEffect} from 'react'
import {Table,tableIn} from './table'
import {columnsRetail} from './columns';
import {NewRetail, GetRetaillist} from './dbfirebase'
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'
import { useSelector } from 'react-redux';




export const ListaRetails = () => {

    const dato = useSelector(state => state.retailInfo)
    const [sended, setSended] = useState()

    const [retails, setRetail] = useState({
        Cuit:'',
        Domicilio:'',
        Email:'',
        Localidad:'',
        Nombre:'',
        Provincia:'',
        RazonSocial:'',
        Telefono:'',
        
    })
    const [place, setPlace] = useState('')

    const ClearForm = () => { 
        document.getElementById("Form").reset();
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setRetail((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if (dato.filter((existingRetail) => existingRetail.Cuit === retails.Cuit).length > 0) {
            toast.error('El número de Cuit ya existe, intente otro', {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true
            });
        } else if (Object.values(retails).some(value => value === "") || place === '') {
            toast.error('Debe completar todos los campos de usuario', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            });
        } else {
           // console.log(retails);
            setSended(true)
            
           await NewRetail(retails);
           toast.success('Datos cargados con éxito', {
               position: "top-left",
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true
           });
        }
    }
    
    return(
        <div>
            <div className='row'>
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Retails</h3>
                    <Ayuda section="listaDeRetails"/> 
                    <ToastContainer/>
            </div>
           

            <div className=' card  '  id="forum-retail" style={{marginTop:'50px'}}>

                <h4 className='d-flex justify-content-center 'style={{marginTop:'50px'}}>Nuevo retail</h4>


                <form id='Form' style={{margin:'50px '}} >
                    <div className="row " style={{margin:'30px'}}>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit/Dni:</label>
                                <input 
                                    type = "text"
                                    name = "Cuit"
                                    className = "form-control"
                                    placeholder = "Sin guiones ni puntos"
                                    id = "Cuit"
                                    autoFocus
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Domicilio:</label>
                                <Autocomplete
                                    type = "text"
                                    name = "Domicilio"
                                    className = "form-control"
                                    placeholder = "Domicilio"
                                    id = "Domicilio"
                                    //onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    apiKey= {process.env.REACT_APP_MAP}
                                    onPlaceSelected={(place, autocomplete) => {
                                        // Obtén la dirección del lugar seleccionado y actualiza el estado 'taller'
                                        const addressComponents = place.address_components || [];
                                        let direccion = '';
                                        let localidad = '';
                                        let provincia = '';

                                        addressComponents.forEach((component) => {
                                            if (component.types.includes('street_number') || component.types.includes('route')) {
                                                direccion += component.long_name + ' ';
                                            }
                                            if (component.types.includes('locality')) {
                                                localidad = component.long_name;
                                            }
                                            if (component.types.includes('administrative_area_level_1')) {
                                                provincia = component.long_name;
                                            }
                                            // Puedes agregar más lógica aquí para otros componentes de dirección si es necesario
                                        });

                                        // Actualiza el estado 'taller'
                                        setRetail((prevRetail) => ({
                                            ...prevRetail,
                                            Domicilio: direccion.trim(),
                                            Localidad: localidad,
                                            Provincia: provincia,
                                        }));
                                        setPlace(place)
                                    }}
                                    options={{
                                        types: ['address'],
                                        componentRestrictions: { country: 'ar' },
                                    }}
                                />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Razon Social:</label>
                                <input 
                                    type = "text"
                                    name = "RazonSocial"
                                    className = "form-control"
                                    placeholder = "Razon Social"
                                    id = "RazonSocial"
                                    onChange={handleFormChange}
                                    disabled={sended ? true : false}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                <input 
                                    type = "text"
                                    name = "Telefono"
                                    className = "form-control"
                                    placeholder = "Telefono"
                                    id = "Telefono"
                                    onChange={handleFormChange}
                                    disabled={sended ? true : false}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />  
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Email:</label>
                                <input 
                                    type = "text"
                                    name = "Email"
                                    className = "form-control"
                                    placeholder = "Email"
                                    id = "Email"
                                    onChange={handleFormChange}
                                    disabled={sended ? true : false}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                                <input 
                                    type = "text"
                                    name = "Nombre"
                                    className = "form-control"
                                    placeholder = "Nombre de fantasia"
                                    id = "Nombre"
                                    onChange={handleFormChange}
                                    disabled={sended ? true : false}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>

                       
                      
                    </div> 
                <div className='d-flex justify-content-center col-12 ' style={{margin:'20px 0 50px' }}>
                    <button type="submit" onClick={handleFormSubmit} disabled={sended ? true : false} className='col-6 btn btn-success w-25'>Guardar</button>    
                </div> 
                </form>
            </div>
                
            <div className= 'row d-flex justify-content-center' id='tabla-retails'>
                {dato && <Table listaOrdenes={dato} exporttable={true} source={"orden"} filter={true} settingcolumns={columnsRetail} rows={20} showEdit={true}/>}
            </div>
        </div>
    )
}