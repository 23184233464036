import React from 'react';

export const ModalConfirmacion = ({ mensaje, onConfirmar, onCancel }) => {
  return (
    <div>
      <p>{mensaje}</p>
      <button className='btn btn-success' style={{marginRight: '1rem'}} onClick={onConfirmar}>Confirmar</button>
      <button className='btn btn-danger' onClick={onCancel}>Cancelar</button>
    </div>
  );
}