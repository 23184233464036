import React,{useState,useEffect} from 'react'
import {Table} from './table'
import {columnsOrdenes} from './columns';
import {columnsRepuestos} from './columns';
import {NewTaller, GetCategorias, GetRepuestos, AddRepuestoPrice, Getpvp, AddDolar} from './dbfirebase'
import nuevodocumento from '../images/nuevo-documento.png'
import { useSelector } from 'react-redux';
import {Ayuda} from './Help'

export const ListaPrecios = () => {

    const [dato, setDato] = useState()
    const [tipo, setTipo] = useState()
    const [repuestos, setRepuestos] = useState('')
    const [taller, setTaller] = useState({
        Articulo:'',
        Categoria:'',
        Tipo:'',
        Ult_Actual:'',
    })
    
    const categorias = useSelector(state => state.categoriaData.categorias)
    
    const [margen, setMargen] = useState()
    const [update, setUpdate] = useState(false)
    const [listaprecios, setListaprecios] = useState([])
    
    const sku = useSelector(state => state.productData.products)

    const [pvp, setPvp] = useState(1)
    const [cdolar, setCdolar] = useState(1)
    const [producto, setProducto] = useState()
    // const data = async(categ) =>{ 
    //     const Categoria = await GetCategorias(categ)
    //     setCategoria(Categoria[0].Categoria)
    // }

    const ClearForm = () => { 
        document.getElementById("Form1").reset();
        document.getElementById("Form2").reset();
    }

    // useEffect(()=>{
    //     data('Categorias')
    // },[])

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setTaller((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setMargen((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleForm2Change = (e) => {
        const { name, value } = e.target;
        setCdolar((prevState) => ({ ...prevState, [name]: parseInt(value) }));
    };

    const handleFormSubmit = async(event) => {
        event.preventDefault()
        const upload = await AddRepuestoPrice(taller.Categoria, taller.Articulo, margen)
        ClearForm()
        setUpdate(true)
    }

    const handleForm2Submit = async(event) => {
        event.preventDefault()
        const upload = await AddDolar(cdolar.Cotizacion)
        ClearForm()
    }
   
    // ------------------------------------ busqueda por producto --------------------
    // const dispatch = useDispatch();

    // const GetSku = async() =>{
    //     const Skus = await GetSkuList()
    //     if (Skus){
    //         setSku(Skus.sku)
    //         const products = Skus.sku;
    //         dispatch(setProducts(products));
    //     }
    // }

     //datos categoria
    //  const Categoria = async() =>{
    //     const cat = await GetCategorias()
    //     setCategorias(cat)
    //     const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria")
    //     setCategoria(categ)
    // }
    
    const Repuestos = async(categoria, articulo) => {
        const Articulos = await GetRepuestos(categoria, articulo)
        setRepuestos(Articulos)
    }

    //Modificar esta funcion para buscar y traer el precio pvp del articulo
    const Pvp = async() =>{
        const d = await Getpvp()
        setPvp(d)
    }
   
    useEffect(()=>{
        Pvp()
    },[])

    const obj = {}
    categorias.map((item) =>{
        [item].map((i)=>{
            Object.assign(obj, i);
        })
    })

    function buscarClave(obj, valorBuscado) {
        for (let i=0; i<=obj.length; i++){
            for (let clave in obj[i]) {
                if (clave === valorBuscado[0]) {
                    return obj[i].Grupo;
                }
            }
        }
        return null; // valor no encontrado
    }

    const buscarCategoria = (objeto, claveBuscada, ruta = []) => {
        for (let nombreCategoria in objeto) {
            let categoria = objeto[nombreCategoria];
            if (typeof categoria === 'object') {
                if (claveBuscada in categoria) {
                    ruta.push(nombreCategoria);
                    ruta.push(claveBuscada);
                    return ruta;
                } else {
                    let resultado = buscarCategoria(categoria, claveBuscada, ruta.concat(nombreCategoria));
                    if (resultado !== false) {
                        let grupo = buscarClave(categorias,resultado)
                        return [resultado, grupo]
                    }
                }
            }
        } 
        return false;
    }
    
    useEffect(() =>{
        const cate = buscarCategoria(obj, taller.Articulo)[1]
        if (cate){
            const type = buscarCategoria(obj, taller.Articulo)[0][0]
            
            Repuestos(cate, taller.Articulo)
            // Actualiza el estado con el valor de la categoría
            setTaller(prevTaller => ({
                ...prevTaller,
                Categoria: cate,
                Tipo: type
            }));
        }
    },[taller.Articulo, update])
    
    
    const lista = []

    useEffect(()=>{
        const listaprecio = []
        if(repuestos[0]){
            const nuevosProductos = repuestos && Object.entries(repuestos[0]).map((repuestos) => {
                repuestos[1].map((r) =>{
                   
                    const PrecioPublico = Object.values(r)[0].PrecioPublico * parseInt(producto?producto.Price:1) //multiplicar por el precio pvp del articulo
                    const PrecioTaller = Object.values(r)[0].PrecioTaller * parseInt(producto?producto.Price:1) //multiplicar por el precio pvp del articulo
                    const Descripcion = Object.values(r)[0].Descripción
                    const FechaAct = Object.values(r)[0].FechaAct
                    const aplicado = Object.values(r)[0].aplicado
                    const c = categorias.map((categoria) => (Object.keys(categoria).includes('Categoria') ? categoria.Categoria[`${taller.Categoria.replace(/\s/g, '')}`]:null)).filter((valor) => valor !== null)
                    lista.push({Categoria:taller.Categoria.replace(/\s/g, '')||'', Tipo:taller.Tipo.replace(/\s/g, ''), Articulo:taller.Articulo, Codigo: Object.keys(r), PrecioPublico, PrecioTaller, Descripcion, FechaAct, aplicado }) //mdo:c[0][`${taller.Tipo.replace(/\s/g, '')}`]
                });
                return lista
              });
              
              listaprecio.push(lista)
              
        };
        setListaprecios(listaprecio)
        setUpdate(false)
    },[repuestos, producto])
   
    const Nuevaorden = () =>{
        console.log('Nuevos precios')
    }

    function formatCategoryLabel(value) {
        // Implementa tu lógica para agregar espacios al valor aquí
        // Por ejemplo, puedes reemplazar mayúsculas con espacios antes de minúsculas
        const formattedValue = value.replace(/([A-Z])/g, ' $1');
        // Capitaliza la primera letra
        return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
    }

    useEffect(() => {
        if (pvp && pvp.Precios) { 
          const productoFiltrado = pvp.Precios.find((producto) => producto.ItemCode === taller.Articulo);
          setProducto(productoFiltrado);
        }
      }, [taller.Articulo, pvp]);
    
    return(
        <div>
            <div className='row'>
                
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Lista de precios</h3>
                    <Ayuda section="listaDePrecios"/>
                
                    <div className='container d-flex justify-content-end'>

                        <a href="/pedidoclientes" className="text-center btn btn-outline-success">Crear nuevo pedido para consumidor final</a>

                    </div>
                
            </div>
            
            <div className='card' style={{marginTop:'50px'}}>

              <h4 className='d-flex justify-content-center' style={{marginTop:'50px'}}>Buscar producto</h4> 

               <form id='Form'  style={{margin:'50px '}} >
                    <div className="row"  style={{margin:'0 25px 0 25px '}}>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Categoria:</label>
                                <input 
                                    type = "text"
                                    name = "Categoria"
                                    className = "form-control"
                                    placeholder = "Categoria"
                                    id = "Categoria"
                                    list='categoria'
                                    autoComplete="off"
                                    autoFocus
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    />
                                    <datalist id="categoria">
                                        {categorias && categorias.map((categoria) => {
                                            
                                            return (
                                            Object.keys(categoria).includes('Grupo') ? (
                                                Object.keys(categoria).map((c) =>{
                                                    return(
                                                        <option key={c} value={formatCategoryLabel(c)}/>
                                                    )
                                                })
                                            ) : null
                                        )})}
                                    </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Tipo:</label>
                                <input 
                                    type = "text"
                                    name = "Tipo"
                                    className = "form-control"
                                    placeholder = "Tipo"
                                    id = "Tipo"
                                    list='tipo'
                                    autoComplete="off"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={!taller.Categoria||taller.Articulo}
                                    />
                                    <datalist id="tipo">
                                        {taller.Categoria && categorias.filter((categoria) => (
                                            categoria.Grupo === taller.Categoria.replace(/\s/g, ''))).map((producto) => {
                                                const nuevoProducto = {};
                                                for (const key in producto) {
                                                    if (key !== "Grupo") {
                                                        nuevoProducto[key] = producto[key];
                                                    }
                                                }
                                                return Object.keys(nuevoProducto).map((art) =>
                                                    <option key={art} value={formatCategoryLabel(art)}/>,
                                                )
                                            })
                                        }
                                        </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Articulo:</label>
                                <input 
                                    type = "text"
                                    name = "Articulo"
                                    className = "form-control"
                                    placeholder = "Articulo"
                                    id = "Articulo"
                                    autoComplete="off"
                                    list='articulo'
                                    onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleFormChange(e); }}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    //disabled={!taller.Categoria || !taller.Tipo}
                                    />
                                    <datalist id="articulo">
                                        {/* {taller.Tipo && categorias.filter((categoria) => (
                                            categoria.Grupo === taller.Categoria.replace(/\s/g, ''))).map((producto) => {
                                                try{
                                                    return Object.keys(producto[`${taller.Tipo.replace(/\s/g, '')}`][0]).map((codigo) =>(
                                                        <option key={codigo} value={codigo}/>
                                                    ))
                                                }catch (error){
                                                    return null
                                                }
                                            }) ||  */}
                                        {sku &&
                                            sku.map(art => (
                                                <option key={art} value={art}>
                                                    {art}
                                                </option>
                                            ))
                                            }
                                    </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Descripcion:</label>
                                <input 
                                    type = "text"
                                    name = "Descripcion"
                                    className = "form-control"
                                    placeholder = "Descripcion"
                                    id = "descripcion"
                                    //onChange={handleFormChange}
                                    disabled={true}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    defaultValue = {taller.Articulo && categorias.filter((categoria) => (
                                        categoria.Grupo === taller.Categoria)).map((producto) => {
                                            try{
                                                return producto[`${taller.Tipo}`][0][`${taller.Articulo}`]
                                            } catch (error){
                                                return null
                                            }
                                            
                                        })}
                                    />  
                        </div>
                    </div>    
                </form>
                
                        {/* <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Provincia:</label>
                                <input 
                                    type = "text"
                                    name = "Provincia"
                                    className = "form-control"
                                    placeholder = "Provincia"
                                    id = "Provincia"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                <input 
                                    type = "text"
                                    name = "Telefono"
                                    className = "form-control"
                                    placeholder = "Telefono"
                                    id = "Telefono"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Mail:</label>
                                <input 
                                    type = "text"
                                    name = "Email"
                                    className = "form-control"
                                    placeholder = "Mail"
                                    id = "Email"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div> */}
                    
            </div>
               
            {taller.Articulo && <div className="row">
                <h4 style={{marginTop:'50px'}}>Actualizacion de valores en precios</h4>
                <p>
                    Ultima actualización de datos:{" "}
                    {pvp && pvp.FechaActualizacion ? (
                        new Date(pvp.FechaActualizacion.seconds * 1000).toLocaleString()
                    ) : (
                        "No disponible"
                    )}
                    </p>
                    <div className='d-flex col-6 justify-content-start' style={{marginTop:'50px'}}>
                        <form id='Form1'>
                        <p>*Setear margen sobre el total de repuestos del producto seleccionado.</p>
                            <div className="row">
                                <div className='col-lg-12  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                
                                    <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Margen:</label>
                                        <input 
                                            type = "number"
                                            name = "margen"
                                            className = "form-control"
                                            placeholder = "Margen"
                                            id = "margen"
                                            onChange={handleForm1Change}
                                            style={{minWidth:'10rem', maxWidth:'19rem'}}
                                            />
                                </div>
                                <button type="submit" onClick={handleFormSubmit} className='btn btn-outline-success'>Guardar</button> 
                            </div>   
                        </form>
                    </div>
                    <div className='d-flex col-6 justify-content-end' style={{marginTop:'50px'}}>
                        <form id='Form2'>
                            <div className="row">    
                                <div className='col-lg-12  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                    <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Precio PvP:</label>
                                        <input 
                                            type = "number"
                                            name = "Cotizacion"
                                            className = "form-control"
                                            placeholder = "Precio desactualizado"
                                            id = "dolar"
                                            //onChange={handleForm2Change}
                                            disabled = {true}
                                            value = {producto?producto.Price:""}
                                            style={{minWidth:'10rem', maxWidth:'19rem'}}
                                            />
                                </div>
                                {/* <button type="submit" onClick={handleForm2Submit} className='btn btn-outline-success'>Guardar</button>  */}
                            </div>   
                        </form>
                    </div>
                </div>}
            <div className= 'row d-flex justify-content-center'style={{marginTop: '150px'}}>
                {listaprecios[0] && <Table listaOrdenes={listaprecios[0]} exporttable={true} source={"orden"} settingcolumns={columnsRepuestos(setUpdate)} rows={20}  setUpdate={setUpdate}/>}
            </div>
        </div>
    )
}