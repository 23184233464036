
import { createSlice } from '@reduxjs/toolkit'

export const ordersInfoSlice = createSlice({

    name: 'ordersInfo',
    initialState: [],
    reducers: {
        addOrdersInfo: (state, action) => {
            return action.payload
        },
    }
})
export const { addOrdersInfo } = ordersInfoSlice.actions
export default ordersInfoSlice.reducer