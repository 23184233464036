import { doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import React,  { useEffect, useState }   from 'react'
import { Navigate, useMatch, useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { GetRemitoslist} from './dbfirebase'
import { upload } from '@testing-library/user-event/dist/upload';
import { toast } from 'react-toastify';

export const ModificarRemito = () => {
    const match = useMatch('/remitos/modificar/:id');
    const id = match.params.id;
    const [iddoc, setIddoc]= useState('')
    const [remitoInfo, setRemitoInfo] = useState('');
    const [Info, setInfo] = useState('');
    const [salida, setSalida] = useState ({
        RemitoSalida: '',}
    )
    const [objetoModif,setObjetoModificar] = useState('')

    const navigate = useNavigate()
       
    // tomar datos Remito  es el Fila que se ve en la tabla(setRemitoInfo),pero dentro tiene varios remitos anidados en array (setInfo )
    useEffect(() => {
        const fetchRemitos = async () => {
            try {
                const remitos = await GetRemitoslist();
                // Encuentra el remito con el ID deseado
                const remito = remitos.find(empresa => empresa.Remitos.some(rem => rem.Remito === id));
                console.log(remito)
                // Si se encuentra el remito, establece la información en el estado
                if (remito) {
                    setRemitoInfo(remito);
                    setIddoc(remito.id)
                    setInfo(remito.Remitos.find(rem => rem.Remito === id))
                    setObjetoModificar(remito.Remitos)
                } else {
                    console.log("El remito no se encontró");
                }
            } catch (error) {
                console.error("Error al obtener remitos:", error);
            }
        }
        if (id) {
            fetchRemitos();
        }
    }, [id]);

    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setSalida((prevState) => ({ ...prevState, [name]: value  }));
    };  
    
    //console.log(iddoc)
    //console.log(Info)
    //console.log(remitoInfo)
    //console.log(salida)
    //console.log(remitosalida)
    //console.log(objetoModif)

    const pushearSalida = () => {
        if (objetoModif && objetoModif.length > 0) {
            const newArray = [...objetoModif];
            const index = newArray.findIndex(item => item.Remito === id);
            if (index !== -1) {
                // Actualizar el valor de remitoSalida sin verificar si ya existe
                newArray[index] = { ...newArray[index], remitoSalida: salida.RemitoSalida };
                setObjetoModificar(newArray);
                console.log(newArray);
            } else {
                console.log("No se encontró ningún objeto con el Remito igual a id");
            }
        } else {
            console.log("El array objetoModif está vacío o no está definido");
        }
    }
    
    console.log(objetoModif)

    
    const Actualizar = async (iddoc) => {
        try {
            const docRef = doc(db, 'Remitos', iddoc);
            // Actualizar el campo Remitos con el contenido de objetoModif
            const dataActualizada = {
                Remitos: objetoModif,
              };
              
              console.log(dataActualizada)

            await updateDoc(docRef, dataActualizada);
            console.log("Documento actualizado correctamente en Firebase.");
        } catch (error) {
            console.error("Error al actualizar el documento en Firebase:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevenir el envío del formulario
        try {
            await Actualizar(iddoc);
            toast.success('Datos cargados con éxito', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            await new Promise(resolve => setTimeout(resolve, 2100));
            navigate('/remitos');
            setTimeout(() => {
                window.location.reload();
            }, 3500);
        } catch (error) {
            console.error(error);
            alert("Ocurrió un error");
        }
    };

    return (
        <>
         
                <form className= 'd-flex justify-content-center card' style={{ marginBottom: '20px' }}>
                    <div className="row " style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc',padding:'50px' }}>
        
                    {remitoInfo && Info && (
                    <div>
                            <h2>Información del Remito</h2>

                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre"
                                        id = "Nombre"
                                        autoFocus
                                    // onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={ remitoInfo.Nombre}
                                        />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre"
                                        id = "Nombre"
                                        autoFocus
                                    // onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={ remitoInfo.Cuit}
                                        />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Trabajado:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre"
                                        id = "Nombre"
                                        autoFocus
                                    // onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={ Info.Trabajado}
                                        />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Bultos:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre"
                                        id = "Nombre"
                                        autoFocus
                                    // onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={ Info.Bultos}
                                        />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Remito:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre"
                                        id = "Nombre"
                                        autoFocus
                                    // onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={Info.Remito}/>
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Remito Salida:</label>
                                    <input 
                                        type = "text"
                                        name = "RemitoSalida"
                                        className = "form-control"
                                        placeholder = { Info.remitoSalida || 'Remito Salida'}
                                        id = "RemitoSalida"
                                        onChange={(e)=>{handleForm1Change(e);
                                                        pushearSalida()}}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}/>
                                        
                            </div>
                           
                            {/* Aquí puedes mostrar más información sobre el remito si es necesario */}
                        </div>
            )}  
            <div className='d-flex justify-content-center col-12' style={{margin:'20px'}}>
                    <button type="submit" onClick={handleSubmit} className='col-6 btn btn-success w-25'>Guardar</button>    
            </div>
     
        </div>
    </form>
    
  


        </>
    );
}; 