import {createContext, useContext, useState, useEffect} from 'react';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword,
        onAuthStateChanged, signOut} from 'firebase/auth';
import {auth} from "../components/firebase";


export const authContext = createContext();

//Creamos un hook para no tener que realziar tantas importaciones
export const useAuth = () => {
    const context = useContext(authContext)
    return context
};

export function AuthProvider({children, setLogged}){
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    
    
    const signup = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password);
    };

    const signin = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
    };

    const logout = () => signOut(auth);

    
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser =>{
            setUser(currentUser);
            setLoading(false);
            setLogged(currentUser !== null);
        });
        return () => unsubscribe();
    }, []);

    return (
        <authContext.Provider value={{signup, signin, user, logout, loading}}>
                {children}
        </authContext.Provider>
        );
}