import React, {useState, useEffect} from 'react'
import {Table,tableIn} from './table'
import {columnsOrdenes} from './columns';
import {NewRetail, GetRetaillist} from './dbfirebase'
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'
import { useSelector } from 'react-redux';


export const Similarities = () => {

    const dato = useSelector(state => state.ordersInfo)
    const [sended, setSended] = useState()
    const [similar, setSimilar] = useState([])
    const [place, setPlace] = useState('')

    const ClearForm = () => { 
        document.getElementById("Form").reset();
    }

    const getsimilardocs = () => {
        const consimilaridad = dato
            .filter((f) => f.similaridades !== undefined && f.similaridades !== null)
            .map((f) => {
                const { Cliente, Taller, iddoc, similaridades } = f;
                return {
                    Cliente: Cliente,
                    Taller: Taller,
                    iddoc: iddoc,
                    ...similaridades // Aplanar el contenido de Similarities
                };
            });
        setSimilar(consimilaridad);
    }
    
    useEffect(() => {
        getsimilardocs()
    },[dato])

    
    // const handleFormChange = (e) => {
    //     const { name, value } = e.target;
    //     setRetail((prevState) => ({ ...prevState, [name]: value }));
    // };
    
  
    // const handleFormSubmit = async () => {
    //     if (dato.filter((existingRetail) => existingRetail.Cuit === retails.Cuit).length > 0) {
    //         toast.error('El número de Cuit ya existe, intente otro', {
    //             position: "top-right",
    //             autoClose: 8000,
    //             hideProgressBar: false,
    //             closeOnClick: true
    //         });
    //     } else if (Object.values(retails).some(value => value === "") || place === '') {
    //         toast.error('Debe completar todos los campos de usuario', {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true
    //         });
    //     } else {
    //        // console.log(retails);
    //         setSended(true)
            
    //     //    await NewRetail(retails);
    //       //  toast.success('Datos cargados con éxito', {
    //          //   position: "top-left",
    //       //      autoClose: 2000,
    //        //     hideProgressBar: false,
    //         //    closeOnClick: true
    //        // });
    //     }
    // }
        return (
            <div>
                <div className='row'>
                    <h3
                        className='col-md-2 p-0'
                        style={{
                            display: 'flex',
                            width: 'auto',
                            justifyContent: window.innerWidth < 768 ? 'start' : 'end',
                            alignItems: 'center',
                        }}
                    >
                        Similitudes
                    </h3>
                </div>
    
            {similar && similar.map((dato, index) => {
                const { Cliente, Taller, iddoc, 0: similarities } = dato;
                return (
                    <div className='accordion' id='accordion' style={{ marginTop: '50px' }} key={`accordion-${index}`}>
                        <div className='accordion-item'>
                            <div
                                className='row accordion-button collapsed m-auto'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapse${index}`}
                                aria-expanded='true'
                                aria-controls={`collapse${index}`}
                            >
                                <div className={`col alert ${true ? 'alert-warning' : 'alert-success'}`} role='alert'>
                                    <h4 className='accordion-header' id={`heading${index}`}>
                                        {Taller} -- {(similarities.indice * 100).toFixed(1)}%
                                    </h4>
                                    <p>Cliente: {Cliente}</p>
                                    <p>Documento: {iddoc}</p>
                                </div>
                            </div>
                            <div
                                id={`collapse${index}`}
                                className='accordion-collapse collapse'
                                aria-labelledby={`heading${index}`}
                                data-bs-parent='#accordion'
                            >
                                <div className='accordion-body'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Documento similar</th>
                                                <th>Indice de similitud</th>
                                                <th>Actualizado</th>
                                                <th>Validacion</th>
                                                <th>Validador</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{similarities.SimilarDoc}</td>
                                                <td>{(similarities.indice * 100).toFixed(1)}%</td>
                                                <td>{similarities.date}</td>
                                                <td>{similarities.validacion ? 'Sí' : 'No'}</td>
                                                <td>{similarities.validador}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                );
            })}
        </div>
    )
}