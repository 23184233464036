import React, {useMemo, useEffect, useState, useRef} from 'react';

export const ModalManual = (image) => {
    
    const OrderRef = useRef(null)

    const closeModal = () => {
        
        const modal = document.getElementById('Modalimagen');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
    };

    const handleCloseModal = () => {
        closeModal();
    };

    window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    
    return (
        <div tabIndex={-1} aria-labelledby="ModalLabel" aria-hidden="true" className="modal fade" id="Modalimagen" ref={OrderRef}>
            <div className="modal-dialog modal-ls modal-dialog-center" style={{display:"inline"}}>
                <div className='container_modal-content'>
                    <img className="imagenes" src={image.image}alt=""/>                
                </div>
            </div>
        </div>
    )
}