import React, {useEffect, useState} from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Barcode } from './barcodes'

export const EtiquetaDespacho = (data, {setUUID}) => {
    
    const [Uid, setUid] = useState('')

    useEffect(() =>{
        const uuid = uuidv4();
        setUid(uuid)
        data.setUUID(uuid.slice(-12))
    },[])
    
    return(
            <div style={{marginTop: '50px', maxWidth: '35rem'}}>
                <div className="d-flex justify-content-center card p-2 border border-dark" >
                {data.filteredData[0].Permiso === "Garante"?
                    <>
                    <label className="d-flex justify-content-center" >Transporte: {data?data.filteredData[0].Transporte_cli:""}</label>
                    <label style={{fontSize: "20px"}}>Enviar a:</label>
                    <table className="table table-bordered border border-dark" style={{marginTop:'10px'}}>
                        <tbody>
                            <tr>
                                <td style={{ width: '12rem'}}>Nombre y apellido:</td>
                                <td>{data?data.filteredData[0].Cliente:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Direccion:</td>
                                <td>{data?data.filteredData[0].Domicilio_cli:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Localidad:</td>
                                <td>{data?data.filteredData[0].Localidad_cli:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Provincia:</td>
                                <td>{data?data.filteredData[0].Provincia_cli:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Codigo postal:</td>
                                <td>{data?data.filteredData[0].CodigoPostal_cli:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Telefono:</td>
                                <td>{data?data.filteredData[0].Telefono_cli:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Piso/Depto:</td>
                                <td>{data?data.filteredData[0].PisoDept_cli:""}</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                    :
                    <>
                    <label className="d-flex justify-content-center" >Transporte: {data?data.filteredData[0].Transporte:""}</label>
                    <label style={{fontSize: "20px"}}>Enviar a:</label>
                    <table className="table table-bordered border border-dark" style={{marginTop:'10px'}}>
                        <tbody>
                            <tr>
                                <td style={{ width: '12rem'}}>Nombre y apellido:</td>
                                <td>{data?data.filteredData[0].Titular:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Direccion:</td>
                                <td>{data?data.filteredData[0].Direccion:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Localidad:</td>
                                <td>{data?data.filteredData[0].Localidad:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Provincia:</td>
                                <td>{data?data.filteredData[0].Provincia:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Codigo postal:</td>
                                <td>{data?data.filteredData[0].CodigoPostal:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Telefono:</td>
                                <td>{data?data.filteredData[0].Telefono:""}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Piso/Depto:</td>
                                <td>{data?data.filteredData[0].PisoDept_cli:""}</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                }
                    <label style={{fontSize: "20px"}}>Remitente:</label>
                    <table className="table table-bordered border border-dark" style={{marginTop:'10px'}}>
                        <tbody>
                            <tr>
                                <td style={{ width: '12rem'}}>Nombre y apellido:</td>
                                <td>GOLDMUND S.A.</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Direccion:</td>
                                <td>Av. Crobara 2550</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Localidad:</td>
                                <td>Tablada</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Provincia:</td>
                                <td>Buenos Aires</td>
                            </tr>
                            <tr>
                                <td style={{ width: '12rem'}}>Telefono:</td>
                                <td>(011) 4452-7794 / 8951</td>
                            </tr>
                        </tbody>
                    </table>
                    { Uid &&
                        <> 
                        {/* <Barcode value={Uid.slice(-12)}/> */}
                        <label>ID: {Uid.slice(-12)}</label>
                        </>
                    }                    
                    
                </div>
                {/* <table className="table table-bordered border border-dark" style={{marginTop:'10px'}}>
                        <tbody>
                            <tr style={{width:"35px"}}>
                            <td rowSpan="5"><label style={{margin: '0px', padding: '0', transform: 'rotate(-90deg)',
                                                            height:"35px", width: '100px', verticalAlign: 'middle', 
                                                            border: '2px solid red', display: 'block'}}>Remitente</label></td>
                                <td>GOLDMUND S.A.</td>
                            </tr>
                            <tr>
                                
                                <td>Manuel A. Ocampo 1170</td>
                            </tr>
                            <tr>
                                
                                <td>Hurlingham</td>
                            </tr>
                            <tr>
                                
                                <td>Buenos Aires</td>
                            </tr>
                            <tr>
                                
                                <td>(011) 4452-1170</td>
                            </tr>
                        </tbody>
                </table> */}
                {/* <div className="containerLabel">
                    <div className="container-remitente">
                        <div className="remitente">Remitente</div>
                    </div>
                    <div className="container-datos">
                        <div className="dato dato1">GOLDMUND S.A.</div>
                        <div className="dato dato2">Manuel A. Ocampo 1170</div>
                        <div className="dato dato3">Hurlingham</div>
                        <div className="dato dato4">Buenos Aires</div>
                        <div className="dato dato5">(011) 4452-1170</div>
                    </div>
	            </div> */}
            </div>

    )
}