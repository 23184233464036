import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SapInfo: [], // Aquí almacenarás los datos de productos
};

const sapInfoSlice = createSlice({
  name: 'SapInfo',
  initialState,
  reducers: {
    addSapInfo: (state, action) => {
      state.SapInfo = action.payload; // Actualiza los datos de productos
    },
  },
});

export const { addSapInfo } = sapInfoSlice.actions;
export default sapInfoSlice.reducer;