import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Await, useMatch } from 'react-router-dom';
import { GetRetaillist } from "./dbfirebase";
import { ToastContainer, toast } from 'react-toastify';
import { modificarRetail } from "./dbfirebase";
import { useNavigate } from 'react-router-dom';


export const EditRetail = () => {
    
    const [sended, setSended] = useState()
    const navigate = useNavigate()

    const asteriscoStyle = {
        color: 'red',
        fontSize: '1.2em', // Tamaño del asterisco
        fontWeight: 'bold', // Texto en negrita
        verticalAlign: 'super', // Alineación vertical para superíndice
      };
  

    const match = useMatch('/retails/modificaciones/:id')
    const id = match.params.id
    const retaildata = useSelector(state => state.retailInfo).filter((item) => item.id === id)

    const [retail, setRetail] = useState({
        Cuit: '-',
        RazonSocial:'-',
        Nombre:'-',
        Domicilio: '-',
        Localidad:'-',
        Provincia:'-',
        Telefono: '-',
        Email:'-',
        id:'-'
    })

    

const handleLoadData = () => {
        if (retaildata.length > 0) {
            const data = retaildata[0];
            
            setRetail(prevCliente => ({
                ...prevCliente,
                Cuit: data.Cuit,
                RazonSocial: data.RazonSocial,
                Localidad: data.Localidad,
                Provincia: data.Provincia,
                Domicilio: data.Domicilio,
                Telefono: data.Telefono,
                Email: data.Email,
                Nombre:data.Nombre,
                id:data.id
               
            }));
        }
    }; 


    useEffect(() =>{
        handleLoadData()
    },[ ])

    //    '-', para no mandar campos vacios a Firebase por que rompe 


    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setRetail((prevState) => ({ ...prevState,
                Cuit: document.getElementById("Cuit").value || '-',
                RazonSocial:document.getElementById("RazonSocial").value || '-',
                Nombre:document.getElementById("Nombre").value || '-',
                Domicilio: document.getElementById("Domicilio").value || '-',
                Telefono: document.getElementById("Telefono").value || '-',
                Localidad: document.getElementById("Localidad").value || '-',
                Provincia: document.getElementById("Provincia").value || '-',
                Email:document.getElementById("Email").value || '-',
                [name]:value
            
        }));
    };  

   

   // Funcion que se ejecuta al presionar guardar
    
   const FromSubmit = async () =>{
    try {
        const sparedRetail = [{...retail}];
     //   console.log(sparedRetail)
    await modificarRetail (id, sparedRetail)
       setSended(true) 
                    toast.success('Datos cargados con exito',{
                        position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        loseOnClick: true})

                        await new Promise(resolve => setTimeout(resolve, 2050));

                        // Navegar a la ruta '/retails'
                        navigate('/retails');
                
                        // Recargar la página
                        setTimeout(() => {
                            window.location.reload();
                        }, 2500); 

                       
                
                
        } catch(error){
            console.log(error)
            alert("ocurrio un error")
        }
    };
 
    

    return (
    <form className= 'd-flex justify-content-center card' style={{ marginBottom: '20px' }}>
     <div className="row " style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc',padding:'50px' }}>
     <ToastContainer />
      <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Cuit<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Cuit"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Cuit"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem',border: retail.Cuit.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                maxLength="15"
                defaultValue = {retaildata[0]?retaildata[0].Cuit:''}
            />
        </div>

        <div className='col-lg-6  ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Domicilio<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Domicilio"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Nombre completo"
                id="Domicilio"
                autoFocus
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Domicilio.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                defaultValue= {retaildata[0]?retaildata[0].Domicilio:''}
            />
        </div>


      
        
        <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Telefono<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Telefono"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Telefono"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Telefono.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                maxLength="30"
                defaultValue = {retaildata[0]?retaildata[0].Telefono:''}
            />
        </div>

        <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Localidad<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Localidad"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Localidad"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Localidad.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                maxLength="30"
                defaultValue = {retaildata[0]?retaildata[0].Localidad:''}
            />
        </div>

        <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Provincia<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Provincia"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Provincia"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Provincia.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                maxLength="30"
                defaultValue = {retaildata[0]?retaildata[0].Provincia:''}
            />
        </div>

        
        <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>E-mail<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="email"
                name="Email"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="ejemplo@ejemplo.com.ar"
                id="Email"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Email.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                defaultValue = {retaildata[0]?retaildata[0].Email:''}
            />
        </div>

       
       

     


        <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Razon Social<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="RazonSocial"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Transporte"
                id="RazonSocial"
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.RazonSocial.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                defaultValue = {retaildata[0]?retaildata[0].RazonSocial:''}
            />
        </div>

        <div className='col-lg-6  ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
            <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Nombre<span style={asteriscoStyle}>*</span>:</label>
            <input
                type="text"
                name="Nombre"
                //onChange = {handlechange} 
                className="form-control"
                placeholder="Nombre completo"
                id="Nombre"
                autoFocus
                onChange={handleForm1Change}
                style={{ maxWidth: '20rem', border: retail.Nombre.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                defaultValue= {retaildata[0]?retaildata[0].Nombre:''}
            />
        </div>

        <div className='col-12 d-flex justify-content-center d-print-none' style={{ marginBottom: '20px' }}>
             <button type="submit" onClick={() => {FromSubmit()}} className='col-12 btn btn-success w-50' disabled={sended ? true : false} >Guardar</button>
        </div>

    </div>
</form>

)
    

};