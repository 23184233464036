import React, {useEffect} from 'react'
import UsuarioService from '../images/UsuarioService.PNG'
import '../css/styles.css';
import {useAuth} from "../context/authcontext";
import { useGeneral } from '../context/generalContext'
import {Ayuda} from './Help'

export const Micuenta = () => {

    const {user, logout} = useAuth();

    const { userData } = useGeneral()

    const perfil = async () => {
        const dato = await userData
        kick(dato)
    }

    const kick = async(dato) => {
        if (dato[0].App === 'Service'){
            await logout()
        }else{
            //await logout()
        }
    }

    useEffect(() => {
        perfil()
    }, [])

    const isNotificationSupported = "Notification" in window;

    const requestNotificationPermission = async () => {
        if ("Notification" in window) {
          try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
              console.log("Permiso concedido para mostrar notificaciones.");
            } else if (permission === "denied") {
              console.log("Permiso denegado para mostrar notificaciones.");
            }
          } catch (error) {
            console.error("Error al solicitar permiso para notificaciones:", error);
          }
        }
    };

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      };
    
    useEffect(() => {
        if (isNotificationSupported && !isMobileDevice()) {
          requestNotificationPermission();
        }
    }, []);

    return(
        <div>
            <div className='row'>
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Mi cuenta</h3>
                <Ayuda section="micuenta"/>
            </div>
            <div className='container'>
                <div className='row d-flex justify-content-center contenedor'style={{margin: '0 auto'}}>
                    <div className= 'col-4 d-flex justify-content-center'style={{margin: '0 auto'}}>
                        <img src={user?user.photoURL:UsuarioService}></img>
                    </div>
                
                <div className= 'col-8 d-flex justify-content-center' style={{margin: '0 auto'}}>
                        <table className="d-flex justify-content-end" style={{borderSpacing: '10px 20px', borderCollapse: 'separate'}}>
                            <tbody>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Responsable:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>{user?user.displayName:''}</td>
                                </tr>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Razon Social:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>Goldmund S.A.</td>
                                </tr>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Telefono:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>011 4452-1170</td>
                                </tr>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Cuit/Cuil:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>30-708600387-9</td>
                                </tr>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Dirección:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>Manuel A. Ocampo 1170, Hurlingham, Buenos Aires Argentina</td>
                                </tr>
                                <tr>
                                    <td style={{height: '2rem',width: '10rem'}}>Mail:</td>
                                    <td style={{height: '2rem',width: '18rem', backgroundColor:'#D9D9D9'}}>{user?user.email:''}</td>
                                </tr>
                            </tbody>
                        </table>
                   </div>
                </div>
            </div>
        </div>
    )
}