import { createSlice } from '@reduxjs/toolkit'


export const tallerInfoSlice = createSlice({
    name: 'tallerInfo',
    initialState: [],
    reducers: {
      addTaller: (state, action) => {
       //esta recibiendo info de dos lados, desde el navbar y ordenestalleres
        const newData = action.payload
        return newData;
      },
    },
  });
  
  export const { addTaller } = tallerInfoSlice.actions;
  export default tallerInfoSlice.reducer;