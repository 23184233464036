//import html2pdf from 'html2pdf-jspdf2'
import React, { useEffect, useRef, useState } from 'react'
import {Print} from './print'
import { useReactToPrint } from 'react-to-print';

export const ModalEtiquetas = (etiquetas) => {
    
    const OrderRef = useRef(null)
    const componentRef = useRef(null);
    const [index, setIndex] = useState()
    const [contenido, setContenido] = useState()
    const [impresion, setImpresion] = useState()
    
    const closeModal = () => {
        const modal = document.getElementById('ModalEtiquetas');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
        
    };

    const handleCloseModal = () => {
         closeModal();   
    };

   window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => { 
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    

    const handlePrint = useReactToPrint({
        content: () => contenido,
        pageStyle: `
        @page {
            size:  10cm 2,5cm; /* A4 landscape */
            margin: 0.1cm; /* Margen de 1cm en todos los lados */
        }`,
    });
    
    const handleCarrouselItemClick = (id) => {
        const content = document.getElementById(id);
        setContenido(content)
        // Haz algo con el ID, como imprimirlo o realizar una acción relacionada con el elemento clicado
      };
    
    
    
    return (
        
            <div className="modal" 
                id="ModalEtiquetas" 
                tabIndex={-1} 
                aria-labelledby="ModalEtiquetas" 
                aria-hidden="true"
                ref={OrderRef}>
                <div className="modal-dialog modal-fullscreen-lg-down modal-lg ">
                    <div className="modal-content  border-0 colorBody rounded-0 modalProd">

                        {/* Encabezado */}
                        <div className="modal-header d-flex border-0 stickyHead">
                            <h5 className='mb-0 textColorGreyM' id='productModalLabel'>
                                Imprimir etiqueta del producto seleccionado:
                            </h5>
                            <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                        </div>

                        {/* Body 1*/}
                        {/* <div className="modal-body border-bottom ms-3" ref={componentRef}>

                                {etiquetas &&       
                                <Print Etiquetas={etiquetas}/>
                                }              
                            
                        </div> */}
                       
                        {etiquetas.Etiquetas && (
                            <div id="etiquetasCarousel" className="carousel slide">
                                <div className="carousel-inner">
                                {etiquetas && etiquetas.Etiquetas.map((etiqueta, index) => (
                                    <div
                                    key={index}
                                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                    style = {{backgroundColor:impresion === contenido?"red":"green"}}
                                    >
                                        <div ref={componentRef} id={index} onClick={() => handleCarrouselItemClick(index)}>
                                            {/* <Print Etiquetas={[etiqueta]} /> */} 
                                        </div>
                                    </div>
                                ))}
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#etiquetasCarousel"
                                    data-bs-slide="prev"
                                    onClick={(e) => {e.stopPropagation(); setImpresion(contenido)}}
                                    style={{height:"20rem"}}
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#etiquetasCarousel"
                                    data-bs-slide="next"
                                    onClick={(e) => {e.stopPropagation(); setImpresion(contenido)}}
                                    style={{height:"20rem"}}
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            )}

          {contenido !== impresion?<button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={handlePrint}
                        >
                            Imprimir Todo
                        </button>:
                        <p className="d-flex justify-content-center" style={{marginBottom:"0.5rem"}}>Seleccionar</p>
                        }
                        {/* {etiquetas.Etiquetas && etiquetas.Etiquetas.map((etiqueta, index) => (
                        <button
                            key={index}
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => handlePrint(index)} // Pasa el índice del elemento a imprimir
                        >
                            Imprimir {index}
                        </button>
                        ))} */}
                    </div>
                </div>
            </div>
    )
}