import React, {useState, useEffect} from 'react'
import nuevodocumento from '../images/nuevo-documento.png'
import {Table} from './table'
import {columnsOrdenes} from './columns';
import {GetOrders} from './dbfirebase'
import {useGeneral} from '../context/generalContext'
import {TarjetaAcordeon} from './tarjetas'
import notificacion from '../images/notification.gif'
import {Ayuda} from './Help'

import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import { ModalOrden } from './ModalOrden';
import excel from '../images/excel.png'
import { useDispatch, useSelector } from 'react-redux';

export const Ordenes = () =>{

    // const [dato, setDatos] = useState('')
    const [data, setData] = useState('')
    const {userData} = useGeneral()
    const [restaurar, setRestaurar] = useState(false)
    const [datosFiltrados, setDatosFiltrados] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchSecondValue,setSearchSecondValue] = useState('');
    const dato = useSelector(state => state.ordersInfo)
    
    const showNotification = () => {
        if ("Notification" in window && Notification.permission === "granted") {
            const notificationOptions = {
            body: "¡Hola! Se han actualizado las ordenes de servicio.",
            icon: notificacion, // Opcional: Ruta a un icono para la notificación
            };
        
            new Notification("Ordenes de servicio actualizadas", notificationOptions);
        }
    };

    const actualdate = new Date();
    const prevdate = new Date(actualdate);
    prevdate.setDate(actualdate.getDate() - 270);
    let unsubscribe = null;
    
    // const fetchData = async () => {
    //     const cliente = await userData;
    //     try {
    //         const docRef = query(collection(db, 'Ordenes'), where("TimeStamp", ">", prevdate));
    //         const querySnapshot = await getDocs(docRef);
            
    //         let datos = [];
    //         querySnapshot.forEach((doc) => {
    //             const d = doc.data();
    //             const i = {'Id': doc.id};
    //             if (d.Cliente !== cliente[0].Taller) {
    //                 datos.push({...d, ...i});
    //             }
    //         });
    
    //         setDatos(datos);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // //Funcion que esta a la escucha de los datos en firebase, solo lo pude hacer correr aqui.
    // useEffect(() => {
    //     fetchData();
    //     return () => {
    //       if (unsubscribe) {
    //         unsubscribe();
    //       }
    //     }
    //   }, [userData]);

    // useEffect(()=>{
    //     // Filtro
    //           // Filtra los datos antes de que se reendericen en la tabla  
    //           const firstLevelFilter = dato && dato.filter((dato) =>
    //             dato.Taller.toLowerCase().includes(searchValue.toLowerCase()) 
    //           );

    //           const secondLevelFilter = firstLevelFilter && firstLevelFilter.filter((dato) =>
    //           dato.Articulo.toUpperCase().includes(searchSecondValue.toUpperCase()) 
    //         );
            
    //         if (searchValue || searchSecondValue){
    //             setDatos(secondLevelFilter)
    //         }else{
    //             fetchData();
    //         }
            
    // },[searchValue, searchSecondValue])

      
    const CalculateDate = (date, days) =>{
        date.setDate(date.getDate() - days)
        return date
    }
    
    const hoy = new Date().toLocaleString().split(',')[0]
    
    const pendingOrders = dato && dato.filter((value) => value.Estado !== 'Finalizado' || value.Estado !== 'Rev. de Pago' || value.Estado !== 'Facturado' || value.Estado !== 'Cerrado' )
    const UnchangedOrders = dato && dato.filter((value) => value.Estado === 'Pendiente')
    const qtyOfTodayOrders = dato && pendingOrders.filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy).length
    
    const nofilteredData = () => {
        setData(dato)
        setRestaurar(false)
    }
    
    useEffect(()=>{
        nofilteredData()
    },[dato])

    const filterTodayOrders = () => {
        setData(pendingOrders.filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy))
        setRestaurar(true)
    }
    

    const fiveDaysAgo = new Date();
    let daysToSubtract01 = 3;
    let i = 0;

    while (i < daysToSubtract01) {
        fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 1);

        const dayOfWeek01 = fiveDaysAgo.getDay();
        if (dayOfWeek01 !== 0 && dayOfWeek01 !== 6) {
            i++;
        }
    }

    const tenDaysAgo = new Date();
    let daysToSubtract02 = 5;
    let j = 0;

    while (j < daysToSubtract02) {
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 1);

        const dayOfWeek02 = tenDaysAgo.getDay();
        if (dayOfWeek02 !== 0 && dayOfWeek02 !== 6) {
            j++;
        }
    }

    
    const qtyOfOrdsOverFiveDays = dato && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);
        const orderTime = orderDate.getTime();
        return orderTime <= fiveDaysAgo.getTime() && orderTime > tenDaysAgo.getTime();
      }).length;
    
    const filterFiveDaysOrders = () => {
        setData(UnchangedOrders.filter((value) => {
            const orderDate = new Date(value.TimeStamp * 1000);
            const orderTime = orderDate.getTime();
            return orderTime <= fiveDaysAgo.getTime() && orderTime > tenDaysAgo.getTime();
          }))
        setRestaurar(true)
    }
    
    const qtyOfOrdsOverTenDays = dato && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);   
        return orderDate.getTime() <= tenDaysAgo.getTime();
      }).length;   


    const filterTenDaysOrders = () => {
        setData(UnchangedOrders.filter((value) => {
            const orderDate = new Date(value.TimeStamp * 1000);   
            return orderDate.getTime() <= tenDaysAgo.getTime();
          }))
        setRestaurar(true)
    }

    const talleres = {}
    dato && dato.forEach(({TimeStamp, Taller}) =>{
        const date = new Date(TimeStamp * 1000);
        if (date >= tenDaysAgo) {
            if (!talleres[Taller]) {
                talleres[Taller] = 0;
            }
            talleres[Taller]++;
        }
    })

    const SixMonthsAgo = new Date();
    SixMonthsAgo.setDate(SixMonthsAgo.getDate() - 180);

    const talleresCalificacion = {}

    dato && dato.forEach(({TimeStamp, Taller, Calificacion}) =>{
        const date = new Date(TimeStamp * 1000);
        if (date >= SixMonthsAgo) {
            
            if (!talleresCalificacion[Taller]) {
                talleresCalificacion[Taller] = { sum: 0, count: 0 };
            }
            if (Calificacion) {
                talleresCalificacion[Taller].sum += Number(Calificacion);
                talleresCalificacion[Taller].count++;
            }
        }
    })

    // Para calcular la media de cada RazonSocial:
    const talleresMediaCalificacion = {}
    
    for (let Taller in talleresCalificacion) {
        const { sum, count } = talleresCalificacion[Taller]; 
        talleresMediaCalificacion[Taller] = sum / (count === 0? 1: count);
    }

    const PresupuestoPendiente = dato && dato.filter((value) => value.Estado === 'Diagnosticado').length

    const filterPresupuestoOrders = () => {
        setData(dato.filter((value) => value.Estado === 'Diagnosticado'))
        setRestaurar(true)
    }

    const generarEstrellas = (calificacion) => {
        const estrellasTotales = 5;
        const estrellasLlenas = Math.floor(calificacion);
        const estrellasMedias = Math.ceil(calificacion - estrellasLlenas);
        const estrellasVacias = estrellasTotales - estrellasLlenas - estrellasMedias;
      
        const estrellaLlena = '\u2605'; // Símbolo Unicode de estrella llena
        const estrellaMedia = '\u2606'; // Símbolo Unicode de estrella vacía
        const estrellaVacia = '\u2606'; // Símbolo Unicode de estrella vacía (puedes utilizar otro símbolo para representar las estrellas vacías)

        const estrellas = [];
      
        for (let i = 0; i < estrellasLlenas; i++) {
          estrellas.push(estrellaLlena);
        }
      
        for (let i = 0; i < estrellasMedias; i++) {
          estrellas.push(estrellaMedia);
        }
      
        for (let i = 0; i < estrellasVacias; i++) {
          estrellas.push(estrellaVacia);
        }
      
        return estrellas.join(' ');
      };


      
    return(
        <>
        <div>
           { data && <ModalOrden listaOrdenes={data} />}
            <div className='row'>
                <h3 className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Ordenes de servicio</h3>
                <Ayuda section="ordenesDeServicio"/>
            </div>
            {/* Indicadores de estado */}
            <div className= 'row justify-content-center mt-3  ' id="OrdenesService-colores"style={{marginTop: '50px'}}>
                <div className="col-12 col-md-4 mb-4 card text-bg-success "style={{width: '10rem', margin:'0 80px'}}  >
                    <div className='card-body' onClick={filterTodayOrders}>
                        <h2 style={{textAlign: 'center'}}>{qtyOfTodayOrders}</h2>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center'}}>
                        Ordenes del dia
                    </div>
                </div>
                <div className="col-4 card text-bg-warning mb-3" style={{width: '10rem', margin:'0 80px'}}>
                    <div className='card-body' onClick={filterFiveDaysOrders}>
                        <h2 style={{textAlign: 'center'}}>{qtyOfOrdsOverFiveDays}</h2>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center'}}>
                        Pendientes mayores a 3 dias
                    </div>
                </div>
                <div className="col-4 card text-bg-danger mb-3" style={{width: '10rem', margin:'0 80px'}}>
                    <div className='card-body' onClick={filterTenDaysOrders}>
                        <h2 style={{textAlign: 'center'}}>{qtyOfOrdsOverTenDays}</h2>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center'}}>
                        Pendientes mayores a 5 dias
                    </div>
                </div>
                <div className="col-4 card text-bg-primary shadow-lg mb-3" style={{ width: '10rem', margin: '0 80px' }}>
                        <div className='card-body' onClick={filterPresupuestoOrders}>
                            <h2 style={{ textAlign: 'center' }}>{PresupuestoPendiente}</h2>
                        </div>
                        <div className="card-footer" style={{ textAlign: 'center' }}>
                            Ordenes con presupuesto pendientes
                        </div>
                </div>
               
            </div>

            {/* <div className='container mt-5 d-flex flex-wrap justify-content-center'>
                    <div className='card text-bg-secondary mb-4 mx-3' style={{ width: '35rem' }}>
                        <div className="card-header">Volumen de trabajo en los últimos 5 días hábiles:</div>
                        <div className='card-body' id='grey-card'>
                            <ul>
                                {talleres && Object.keys(talleres).map(key => (
                                <li key={key}>
                                    {key + " >> " + talleres[key] + `${talleres[key] > 1 ? " Unidades." : " Unidad."}`}
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className='card text-bg-secondary mb-4 mx-3' style={{ width: '35rem' }}>
                        <div className="card-header">Promedio calificaciones de los últimos 6 meses:</div>
                        <div className='card-body' id='grey-card' >
                            <ul>
                                {talleresMediaCalificacion && Object.keys(talleresMediaCalificacion).map(key => (
                                <li key={key}>
                                    {key + " >> " + generarEstrellas(talleresMediaCalificacion[key])}
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* tabla con datos */}
            <div style={{marginTop: '50px', width:'100%'}} >
                
                {restaurar && 
                    <button onClick={nofilteredData} className='col-12 btn btn-outline-success'style={{marginBottom: '50px'}} >Restaurar datos</button>
                }

            <div className="table-container ">
                <div className='d-flex ' style={{ marginBottom:'20px'}}>
                    <span style={{paddingLeft:'30px'}}>
                        Buscar Taller: {' '}
                        <input
                            value={searchValue || ''}
                            onChange={(e) => setSearchValue(e.target.value)}
                            list="tallerOptions"
                            style={{ width: '15rem' }}
                        />
                        <datalist id="tallerOptions">
                            {dato && dato.length > 0 &&
                                [...new Set(dato.map(item => item.Taller))]
                                    .map((Taller, index) => (
                                        <option key={index} value={Taller} />
                                    ))
                            }
                        </datalist>
                    </span>
                    <span style={{paddingLeft:'30px'}}>
                        Buscar Articulo:{' '}
                        <input
                            value={searchSecondValue || ''}
                            onChange={(e) => setSearchSecondValue(e.target.value)}
                            list="artOptions"
                            style={{ width: '15rem' }}
                        />
                        <datalist id="artOptions">
                            {dato && dato.length > 0 &&
                                [...new Set(dato.map(item => item.Articulo))]
                                    .map((Articulo, index) => (
                                        <option key={index} value={Articulo} />
                                    ))
                            }
                        </datalist>
                        
                    </span>
                </div>

                <div className='d-flex justify-content-end'>
                    <button type="button" className="btn btn-outline-secondary"  
                            data-bs-toggle="modal"
                            data-bs-target="#modalTableOrder" 
                            >
                                <img src={excel} width="50rem" height="50rem" />
                    </button>
                    
                </div>  
              { data
                && <Table 
                    showDetail={true} showComponents={true} 
                    showGetImag={true} showGetDocImag={true} 
                    showDelete={true} listaOrdenes={data}
                    source={"orden"} settingcolumns={columnsOrdenes} showGetImag2={true}
                    fiveDaysAgo={fiveDaysAgo} filterState={true}
                    filter={true} rows={20}/>}
                    
            </div>
                    <div className="acordeon-container">
                       
                    {
                        data
                        &&
                        <TarjetaAcordeon data={data}/>
                    }
                    </div>
                </div>
        
        </>
    )
}