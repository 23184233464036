import React, {useState, useEffect} from 'react'
import nuevodocumento from '../images/nuevo-documento.png'
import {Table} from './table'
import {columnsPedidoRepuestos} from './columns';
import {GetOrders} from './dbfirebase'
import imgfullscreen from '../images/fullscreen.png'
import imgclosefullscreen from '../images/closefullscreen.png'
import {ModalEtiquetas} from './modalEtiquetas'
import {Ayuda} from './Help'
import { Link } from 'react-router-dom';
import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import {BarScanner} from "./BarScanner"
import { ModalRemito } from './ModalRemito';
import excel from '../images/excel.png'


export const PedidoRepuestos = () =>{
    const [data, setData] = useState([])
    const [setupdate, setUpdate] = useState(false)
    const [dato, setDatos] = useState('')
    const [estadorepuestos, setEstadorepuestos] = useState("Pendiente")
    const [filas, setFilas] = useState()
    const [etiquetasPendientes, setEtiquetasPendientes] = useState()
    //----------------filtros-----------------
    const [searchTecnico, setSearchTecnico] = useState('');
    const [searchCliente, setSearchCliente] = useState('');
    const [searchProducto, setSearchProducto] = useState('');
    const [searchDespacho, setSearchDespacho] = useState('');
    const [datoChanged, setDatoChanged] = useState(true);
    //Funcion que esta a la escucha de los datos en firebase, solo lo pude hacer correr aqui.
    useEffect(() => {
        const fetchData = async () => {
            const actualdate = new Date();
            const prevdate = new Date(actualdate);
            prevdate.setDate(actualdate.getDate() - 270);
            try {
                const docRef = query(collection(db, 'Ordenes'), where('TimeStamp', '>', prevdate));
                const querySnapshot = await getDocs(docRef);
                let newData = [];
                querySnapshot.forEach(doc => {
                    const d = doc.data();
                    newData.push({ ...d });
                });
                // Filtro
                // Filtra los datos antes de que se reendericen en la tabla
                const tecnicoMatch = newData.filter(datos =>
                    datos.Taller.toLowerCase().includes(searchTecnico.toLowerCase())
                );
                const clienteMatch = tecnicoMatch.filter(datos =>
                    datos.Cliente.toLowerCase().includes(searchCliente.toLowerCase())
                );
                const productoMatch = clienteMatch.filter(datos =>
                    datos.Articulo.toLowerCase().includes(searchProducto.toLowerCase())
                );
                setDatos(productoMatch);
                setDatoChanged(true); // Establece datoChanged a true cuando se actualizan los datos
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        setUpdate(false)
        return () => {
            // Agrega la función de limpieza
            // Descomenta esta sección si es necesario limpiar suscripciones o recursos cuando se desmonta el componente
            // if (unsubscribe) {
            //     unsubscribe();
            // }
        };
    }, [ setupdate, searchTecnico, searchCliente, searchProducto]);
    
    const CalculateDate = (date, days) =>{
        date.setDate(date.getDate() - days)
        return date
    }

    useEffect(()=>{
        let filas = [];
        // Verificar si existe el campo "Repuestos" y si tiene valores
        dato && dato.forEach((dato)=>{
            if (dato.Repuestos && Array.isArray(dato.Repuestos) && dato.Repuestos.length > 0 && dato.Taller !== 'Goldmund (Central)') {
                dato.Repuestos.forEach((repuesto) => {
                    const despachoDateObj = repuesto?.Estado;
                    // const despachoDate = despachoDateObj?.map((des) => des.Despachado)
                    const despachoDate = despachoDateObj? despachoDateObj
                                                            .filter((des) => des.Despachado)
                                                            .sort((a, b) => new Date(b.Despachado) - new Date(a.Despachado))
                                                            .map((des) => des.Despachado)
                                                        : null;
                    const fila = {
                        Fecha: dato.Fecha,
                        Estado: repuesto.Estado,
                        UltEstado: repuesto.UltEstado,
                        FDespachado: despachoDate,
                        FechaAct: repuesto.Ult_Act,
                        iddoc: dato.iddoc,
                        Taller: dato.Taller,
                        Falla: dato.Desperfecto_declarado,
                        Direccion: dato.Direccion,
                        Cliente: dato.Cliente,
                        Articulo: repuesto.Articulo,
                        Producto: dato.Articulo,
                        Descripcion: repuesto.Descripcion,
                        Cantidad: repuesto.cantidad,
                        Garantia: dato.Garantia,
                        NumFactura: dato.NumFactura,
                        FechaCompra: dato.FechaCompra,
                        RazonSocialCli: dato.RazonSocialCli,
                        Transporte: dato.Transporte,
                        OrdenTaller: dato.Num_Os,
                        Lote:repuesto.Lote,
                        id:repuesto.id||""
                    };
                filas.push(fila);
            });
            }
        })
        setFilas(filas)
        setEtiquetasPendientes(filas.filter((etiqueta) => etiqueta.UltEstado === "Autorizado"));
    },[dato])
    
    const toggleFullscreen = (ID) =>{
        document.getElementById(ID).requestFullscreen()
        document.getElementById("inp").style.display = 'none'
    }

    const handleButtonClick = () => {
       ; // Cambiar el estado de estadorepuestos
      };
            //---------------------------------------------------------------------------//
            //-----------------------terminar-los-data-list------------------------------//
            //---------------------------------------------------------------------------//
    return(
        <div>
             {filas && <ModalRemito listaOrdenes={filas}  />}
            <div className='row'>
                
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Solicitudes de repuestos</h3>
                    <Ayuda section="repuestos"/>
            </div>
            {/* Indicadores de estado */}
            <div className= 'row d-flex justify-content-center'style={{marginTop: '50px'}}>
                {/* <div className="col-lg-4"> 
                    <button className='btn btn-success' onClick={handleButtonClick} data-bs-toggle="modal" data-bs-target="#ModalEtiquetas">
                        Preparar Etiquetas
                    </button>
                </div> */}
                <div className="col-lg-4">
                    <h3 className="p-2 d-flex justify-content-center">Autorizaciones</h3>
                    {/* <BarScanner setData={setData}/> */}
                    <Link to="/autorizaciones" className="btn btn-primary p-2 d-flex justify-content-center">Ir a Autorizaciones</Link>
                </div>
                <div className="col-lg-4">
                    <h3 className="p-2 d-flex justify-content-center">Escanear etiquetas</h3>
                    {/* <BarScanner setData={setData}/> */}
                    <Link to="/despacho" className="btn btn-primary p-2 d-flex justify-content-center">Ir a Despacho</Link>
                </div>
                <div className="col-lg-4">
                    <h3 className="p-2 d-flex justify-content-center">Preparar remitos</h3>
                    {/* <BarScanner setData={setData}/> */}
                    <Link to="/confremitos" className="btn btn-primary p-2 d-flex justify-content-center">Ir a Remitos</Link>
                </div>
        </div>
         {filas && <ModalEtiquetas Etiquetas={etiquetasPendientes}/>}
        {/* tabla con datos */}
        <div className="card mx-3 " style={{marginTop: '50px',  border: 'none', }} id="A1">
            <div className=' ' id="inp">
            <input  type="image" onClick={() => toggleFullscreen('A1')} style={{border: "0px",width:'2vh', height:'2vh', textAlign: 'center'}} src={imgfullscreen}/> 
                <div className='row' id='filtro-remito' style={{marginBottom: '30px'}} >
                            <div className='col-3'>
                                <span>
                                Buscar Técnico: {' '}
                                <input
                                    list="tecnicoOptions"
                                    onChange={(e) => setSearchTecnico(e.target.value)}
                                    value={searchTecnico || ''}
                                />
                                <datalist id="tecnicoOptions">
                                    <option value="">Seleccione Técnico</option>
                                    {filas && filas.length > 0 &&
                                        [...new Set(filas.map(item => item.Taller))]
                                            .map((tecnico, index) => (
                                                <option key={index} value={tecnico} />
                                            ))
                                    }
                                </datalist>
                                </span>
                            </div>
                            <div className='col-3'>
                                <span>
                                Buscar Cliente: {' '}
                                <input 
                                    list="clienteOptions" 
                                    onChange={(e) => setSearchCliente(e.target.value) } 
                                    value={searchCliente || '' } 
                                />

                                <datalist id="clienteOptions"> 
                                    {filas && filas.length > 0 &&
                                        [...new Set(filas.map(item => item.Cliente))]
                                            .map((cliente, index) => ( 
                                                <option key={index} value={cliente} />
                                            ))
                                    }
                                </datalist>
                                </span>
                            </div>

                                
                            <div className='col-3'>
                                <span>
                                Buscar Producto: {' '}
                                <input 
                                    list="productoOptions" 
                                    onChange={(e) => setSearchProducto(e.target.value)} 
                                    value={searchProducto || '' } 
                                />

                                <datalist id="productoOptions">
                                    {filas && filas.length > 0 &&
                                        [...new Set(filas.map(item => item.Producto))]
                                            .map((Producto, index) => ( 
                                                <option key={index} value={Producto} />
                                            ))
                                    }
                                </datalist>
                                </span>
                            </div>

                            <article className='col-3'>
                                <span className='d-flex align-items-center'>  
                                
                                    <select onChange={(e) => setSearchDespacho(e.target.value)} value={searchDespacho  || '' }>    
                                        <option value="">Seleccione Transporte</option>
                                        {filas && filas.length > 0 && // Verificar que filas no sea undefined ni esté vacío
                                            [...new Set(filas.map(item => item.Transporte))] // Obtener valores únicos
                                            .map((Transporte, index) => (
                                                <option key={index} value={Transporte}>{Transporte}</option>
                                            ))
                                        }
                                    </select>                       
                                </span>
                            </article>
                    
                </div>                         
            </div>

            <div className='d-flex justify-content-end'>
                    <button type="button" className="btn btn-outline-secondary"  
                            data-bs-toggle="modal"
                            data-bs-target="#modalTable" 
                            >
                                <img src={excel} width="50rem" height="50rem" />
                            </button>  
                </div>

                {filas && <Table showDetail={false} showhide={true} showComponents={true} showDelete={false} listaOrdenes={filas} source={"orden"} settingcolumns={columnsPedidoRepuestos(setUpdate)} statefilterrepuestos={true} rows={500} showMarkbutton={true} estadorepuestos={estadorepuestos} setEstadorepuestos={setEstadorepuestos} showPrintbutton={true} multifilter={true} filter={true}/>}
         </div>
     </div>
    )
}