import React, {useMemo, useEffect, useState, useRef} from 'react';
import nodisponible from '../images/imagen-no-disponible.jpg'

export const ModalProducto = (url, setUrl) => {

    const OrderRef = useRef(null)

    const closeModal = () => {
        
        const modal = document.getElementById('Modalimg');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
    };

    const handleCloseModal = () => {
        closeModal();
    };

    window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    
    
    return (
        <div tabIndex={-1} aria-labelledby="ModalLabel" aria-hidden="true" className="modal fade" id="Modalimg" ref={OrderRef}>
            <div className="modal-dialog modal-ls modal-dialog-center" style={{display:"inline"}}>
                <div className='container modal-content' style={{overflowX: "auto", width:"auto", height:"auto", marginTop:"80px"}}>
                    {/* <img className="d-flex justify-content-center" style={{marginTop:"10px"}} src={url.url} alt="" 
                        onError={(e) => {
                        e.target.src = nodisponible;
                        }}
                    /> */}
                    <embed className="d-flex justify-content-center" style={{marginTop:"10px"}} src={url.url} type="application/pdf" width="100%" height="600px" />              
                </div>
            </div>
        </div>
    )
}