import React, {useMemo, useEffect, useState, useRef} from 'react';
import {useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter, useFilters} from 'react-table';
import { NavLink, useNavigate } from 'react-router-dom';
import {GlobalFilter, StateFilter, DateFilter, StateFilterRepuestos, MultiGlobalFilter} from './globalfilter'
import left from '../images/left.png'
import next from '../images/next.png'
import {ExportTable} from './exportTable'
import {OrdenModal} from './modal'
import {GetImage, AddRepuestoState} from './dbfirebase'
import {ModalProducto} from './modal2'
import nodisponible from '../images/imagen-no-disponible.jpg'
import {ModalEtiquetasQr} from './modalQr'
import { Timestamp } from 'firebase/firestore';
import {ModalEtiquetas} from './modalEtiquetas'
import edit from '../images/edit.png'
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { Checkbox } from './tablecheckbox';

export const Table = ({showModify,showDetail,showComponents, showDetailorder, showMarkbutton, showGetImag, showGetDocImag,showGetImag2, 
                        showDelete, listaOrdenes, source, settingcolumns, fiveDaysAgo, filter,filterState, statefilterrepuestos,exporttable,
                        multifilter, showTimestamp, rows, estadorepuestos, setUpdate, showQuantity, setPedido, pedido, showQrCode, showPrintbutton,showEdit,showEditTaller,showEditRemito}) =>{
   
    const navigate = useNavigate();
    
    // datos del producto seleccionado
    const [productInfo, setProductInfo] = useState({})

    // datos del pedido seleccionado
    const [documentInfo, setDocumentInfo] = useState({})

    //Guardamos los datos del pedido
    const [selecteddata, setSelecteddata] = useState([]);

    // datos del producto seleccionado
    const [productComponents, setProductComponents] = useState()

    // Datos de imaneges
    const [url, setUrl] = useState()
    const [estadorepuesto, setEstadorepuesto] = useState("Pendiente")

    //useMemo evita que en cada renderizado se vuelvan a llamar los datos
    const columns = useMemo(() => settingcolumns, [])
    const data = useMemo(() => listaOrdenes, [listaOrdenes])

    //Definimos la cantidad de filas que muestra la tabla.
    const numberofRows = rows

    

    //Referencia a la tabla
    const tableRef = useRef(null)

    useEffect(() =>{
        let lista = []
        selecteddata.map((i) =>{
            lista.push(i.CodProducto)
        })
        setProductComponents(...lista)
        
    },[]);

     
    //Setea el hook productInfo para renderizar un modal
    const productDetail = (rowData) => {
        setProductInfo(rowData)
        setDocumentInfo(rowData)
    }

    //funcion que ejecuta al clickear detalles
    const handleDetails = (rowData) => {
        productDetail(rowData)
    }

    const image = async(name) =>{
        
        try{
            setUrl(nodisponible)
            const url =  await GetImage(name)
            setUrl(url)
        }catch{
            //setUrl(nodisponible)
        }
       
    }

 
    // const handleStateChange = async(event, datosfila) =>{
    //     await AddRepuestoState(datosfila, event.target.value)
    // }

  


    const addDetailCol = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "detalles",
                    Header: "Detalle",
                    Cell: ({row}) => (
                        <div>
                            {(source === "orden") &&
                                <a href="#" className="detailsLink" onClick={() => { handleDetails(row.original) }} data-bs-toggle="modal" data-bs-target="#Modal">
                                    Ver
                                </a>
                                // <ProductDetail  handleDetails={handleDetails} row={row} productInfo={productInfo}/>
                            }
                        </div>
    )}])}}

    const addTimestampCol = (hooks) => {
        if (showTimestamp) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "TimeStamp",
                    Header: "TimeStamp",
                    Cell: ({ row }) => {
                        const daysDifference = Math.floor(
                            Math.floor(Date.now() / 1000 - row.original.Fecha) / (60 * 60 * 24)
                        );
                        
                        const pendiente = (row) => row.original.Trabajado ? row.original.Bultos - row.original.Trabajado : row.original.Bultos;
                        const terminado = pendiente(row)
  
                       
                        
                        // Establecer el estilo condicional

                        let backcol;
                        if ((terminado === 0 || terminado === '0')) {
                            backcol = '#39db18'; // Establecer el color de fondo a verde si terminado y daysDifference son ambos igual a 0
                        } else if (daysDifference > 50) {
                            backcol = '#E43819';
                        } else if (daysDifference > 30) {
                            backcol = '#E4CB19';
                        } else {
                            backcol = '';
                        }
                        
                        const cellStyle = {
                            backgroundColor: backcol,
                        };
    
    
                        return (
                            <div>
                                <p style={cellStyle} >
                                    {daysDifference} días
                                </p>
                            </div>
                        );
                    }}])}}

    const diffBusinessDays = (startDate, end, terminado) => {
        // Copia las fechas para evitar modificar los objetos originales
        const parts = startDate.split('/'); // dividir la cadena por el carácter '/'
        const startD = `${parts[2].split(',')[0]}-${parts[1]}-${parts[0]}`
        let start = new Date(startD)
        
        // Si la fecha de inicio es posterior a la fecha de finalización, intercambiarlas
        if (start > end) {
          let temp = start;
          start = end;
          end = temp;
        }
      
        // Inicializar contador de días laborables
        let count = 0;
        
        // Iterar sobre cada día en el rango
        while (start <= end) {
          // Si el día no es sábado ni domingo, incrementar el contador
          if (start.getDay() !== 0 && start.getDay() !== 6) {
            count++;
          }else if ((terminado === 0 || terminado === '0')) {
            // Si pendiente es 0, salir del bucle
            break;
        }
          // 30623893096	YUHMAK	29/2/2024	| 0615-00034439 | 0 | 0 | 0 | 35 dias 
          // si aumenta mañana no anda
          
          // Avanzar al siguiente día
          start.setDate(start.getDate() + 1);
        }
      
        return count;
      }
      

    const fechasCierre = (Actualizado) => {
        const objeto = Actualizado[Actualizado.length - 1]
        for (const clave in objeto) {
            if (clave === 'Fecha_finalizado' || clave === 'Fecha_Despachado' || clave === 'Fecha_reparando' || clave === 'Fecha_paraRetirar') {
                const fechaString = objeto[clave];
                const parts = fechaString.split('/'); // dividir la cadena por el carácter '/'
                const startD = `${parts[2].split(',')[0]}-${parts[1]}-${parts[0]}`
                const fecha = new Date(startD);

                return fecha;
            }
        }
    }

    const ultimamodificacion = (Actualizado) => {
        const objeto = Actualizado[Actualizado.length - 1]
        for (const clave in objeto) {
            if (clave === 'usuario') {
                const userString = objeto[clave];
                return userString;
            }
        }
    }

    const addlastuserCol = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "LastUser",
                    Header: "Ultimo Usuario",
                    Cell: ({row}) => {
                        return(
                            <div>
                                {(source === "orden") &&
                                    <p>{ultimamodificacion(row.original.Actualizado)}</p> 
                                }
                            </div>
                        )
                    },
                    accessor: (originalRow) => {
                        return (source === "orden")?
                         ultimamodificacion(originalRow.Actualizado) : "";
                    }
        }])}}

        //modificar retail

        const ModificarRet = (id) => {
        navigate(`/retails/modificaciones/${id}`)
            
        }

        const Modificar = (hooks) => {
            if (showEdit) {
                hooks.visibleColumns.push((columns) => [
                    ...columns,
                    {
                        id: "Edit",
                        Header: "Modificar",
                        Cell: ({ row }) => (
                            <div className='d-flex justify-content-center'>     
                                <img src={edit} alt= 'edit' style={{ cursor: 'pointer', width: '30px', height: '30px'}} onClick={() => {ModificarRet(row.id)}}/>
                            </div>
                        )
                    }
                ])
                 }
        }
                        
        //modificar taller
         
        const ModificarTaller = (Id) => {
            navigate(`/ordenesTaller/modificar/${Id}`)          
        }

        const ModificarT = (hooks) => {
            if (showEditTaller) {
                hooks.visibleColumns.push((columns) => [
                    ...columns,
                    {
                        id: "Edit",
                        Header: "Modificar",
                        Cell: ({ row }) => (
                            <div className='d-flex justify-content-center'>        
                                <img src={edit} alt= 'edit' style={{ cursor: 'pointer', width: '30px', height: '30px'}} onClick={() => {ModificarTaller(row.original.Id)}}/>
                            </div>
                        )
                    }
                ])
            }
        }


        const ModificarRemito = (id) => {
        navigate(`/remitos/modificar/${id}`)         
        }

        const ModificadorRemito = (hooks) => {
            if (showEditRemito) {
                hooks.visibleColumns.push((columns) => [
                    ...columns,
                    {
                        id: "Edit",
                        Header: "Modificar",
                        Cell: ({ row }) => (
                            <div className='d-flex justify-content-center'>        
                                <img src={edit} alt= 'edit' style={{ cursor: 'pointer', width: '30px', height: '30px'}} onClick={() => {ModificarRemito(row.original.Remito)}}/>
                            </div>
                        )
                    }
                ])
            }
        }
        
       
        const adddaysfilterCol = (hooks) => {
            if (showDetail) {
                hooks.visibleColumns.push((columns) => [
                    ...columns,
                    {
                        id: "Indicador",
                        Header: "Leadtime",
                        Cell: ({ row }) => {
                            
                            const leadtime = (source === "orden") ? 
                                (row.original.Estado === 'Finalizado' || row.original.Estado === 'Despachado' || row.original.Estado === 'Reparado' || row.original.Estado === 'Retirar'? diffBusinessDays(row.original.Fecha, fechasCierre(row.original.Actualizado)) : diffBusinessDays(row.original.Fecha, new Date())) :
                                '-';
        
                            const filaStyle = leadtime > 10 && row.original.Estado !== 'Finalizado' && row.original.Estado !== 'Despachado' && row.original.Estado !== 'Reparado' && row.original.Estado !== 'Retirar' && row.original.Estado !== 'Cancelado'? { backgroundColor: '#EC7063', color: 'white' } : {};
        
                            return (
                                <div style={filaStyle}>
                                    <p>{leadtime}</p>
                                </div>
                            );
                        },
                        // accessor: (originalRow) => {
                        //     return (source === "orden") ? 
                        //         (originalRow.Estado === 'Finalizado' ? '-' : diffBusinessDays(originalRow.Fecha, new Date())) :
                        //         '-';
                        // },
                        //disableSortBy: row.original.Estado === 'Finalizado' // Deshabilitamos el ordenamiento para los registros con Estado "Finalizado"
                    }
                ]);
            }
        }
        

    const addgetimag = (hooks) => {
        if (showGetImag) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Imagen",
                    Header: "Descargar foto",
                    Cell: ({row}) =>(
                        <div>
                            {row.original.Garantia && 
                            <a onClick={()=>image('/ProductoOrdenes/'+row.original.iddoc+'_'+row.original.CuitDni_cli)} href="#!" data-bs-toggle="modal" data-bs-target="#Modalimg">
                               Foto
                            </a>}
                        </div>
                    ),
        }])}}
        
        const showImageModal = async (rutaImagen, extensions) => {
            try {
                const storage = getStorage();
                let imageUrl = null;
                for (const extension of extensions) {
                    try {
                        const imagenRef = ref(storage, rutaImagen + '.' + extension);
                        imageUrl = await getDownloadURL(imagenRef);
                        // Si encontramos la imagen, salimos del bucle
                        break;
                    } catch (error) {
                        // Si hay un error, probamos con la siguiente extensión

                    }
                }
                
                if (imageUrl) {
                    
                    console.log("URL de la imagen:", imageUrl);
                    
                    image(imageUrl)
                } else {
                    console.error("No se pudo encontrar la imagen.");
                }
              
            } catch (error) {
                console.error("Error al obtener la imagen:", error);
                // Manejar el error adecuadamente
            }
        }
        
        const addgetimag2 = (hooks) => {
            if (showGetImag2) {
                const extensions = ['jpg', 'jpeg', 'png', 'pdf', 'jfif'];
                hooks.visibleColumns.push((columns) => [
                    ...columns,
                    {
                        id: "ImagenSeguimiento",
                        Header: "Ver seguimiento",
                        Cell: ({row}) => {
                            return (
                                <div>
                                    {row.original.Estado === "Envio a central" && (
                                        <button className='btn btn-primary' onClick={() => showImageModal('OrdenComprobanteEnvio/' + row.original.Email + '/' + row.original.Id, extensions)} data-bs-toggle="modal" data-bs-target="#Modalimg">
                                            Ver Foto
                                        </button>
                                    )}
                                </div>
                            );
                        }
                    }
                ]);
            }
        }
            
    const addgetdocimag = (hooks) => {
        if (showGetDocImag) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Factura",
                    Header: "Descargar factura",
                    Cell: ({row}) =>(
                        <div>
                            {row.original.Garantia &&
                            <a onClick={()=>image('/factura_de_compras/'+row.original.iddoc+'_'+row.original.FechaCompra+'_'+row.original.RazonSocialCli+'_'+row.original.NumFactura)} href="#!" data-bs-toggle="modal" data-bs-target="#Modalimg">
                                Factura
                            </a>}
                        </div>
                    ),
                    //accessor: (originalRow) => originalRow.Estado === 'Finalizado' ? '-' : diffBusinessDays(originalRow.Fecha, new Date()), // Agregamos el accessor para permitir el ordenamiento
                    //disableSortBy: row.original.Estado === 'Finalizado' // Deshabilitamos el ordenamiento para los registros con Estado "Finalizado"
        }])}}

    const handleSelectChange = (event, datosfila) => {
        const { value } = event.target;
        setPedido([...pedido, { ...datosfila, cantidad: value, Ult_Act: Timestamp.now().seconds *1000}])
    };

    //Columna de detalle
    const addQuantity = (hooks) => {
        if (showQuantity) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Quantity",
                    Header: "Cantidad",
                    Cell: ({ row }) => (
                        <select value={row.original.cantidad}
                            onChange={(event) => handleSelectChange(event, row.values)}
                            //onChange={(event) => handleQuantityChange(event, row.original)}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            
                        </select>
                    )
                }
            ])
        }
    }

    const qrCode = (hooks) => {
        if (showQrCode){
            hooks.visibleColumns.push((columns) => [
                ... columns,
                {
                    id:"qr",
                    Header: "Generar Qr",
                    Cell: ({row}) =>(
                        <div>
                            <a href="#" onClick={(()=>{handleDetails(row.original)})} className="detailsLink" data-bs-toggle="modal" data-bs-target="#ModalEtiquetasQr">
                                Ver Qr
                            </a>
                        </div>
                    )
                }
            ])
        } 
    }

    const printrepuesto = (hooks) => {
        if (showPrintbutton){
            hooks.visibleColumns.push((columns) => [
                ... columns,
                {
                    id:"print",
                    Header: "Imprimir Etiqueta",
                    Cell: ({row}) =>(
                        <button className='btn btn-success' onClick={(()=>{handleDetails(row.original)})} data-bs-toggle="modal" data-bs-target="#ModalEtiquetas">
                            Preparar Etiquetas
                        </button>
            
                    ),
                }
            ])
        }
    }

    //Configuracion de las funciones de la tabla
    const tableInstance = useTable({
        columns,
        data,
        initialState : {pageSize: numberofRows}},
        useGlobalFilter, 
        useSortBy, 
        usePagination, 
        useRowSelect,
        adddaysfilterCol,
        addlastuserCol,
        addDetailCol,
        addTimestampCol,
        addgetimag,
        addgetimag2,
        addgetdocimag,
        addQuantity,
        qrCode,
        Modificar,
        ModificarT,
        // ModificadorRemito, 
             
        );
    
    //Seleccion de las instancias que requiere la tabla
    const {
        getToggleHideAllColumnsProps,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state, 
        prepareRow,
        selectedFlatRows,
        allColumns,
        pageCount: controlledPageCount,
        setGlobalFilter,
    } = tableInstance

    const {pageIndex, globalFilter} = state

    //renderizado de tabla con filtros y checkbox
    return (
        <div>
            {/* <h1>{source}</h1> */}
                {/* <ProductDetail productInfo={productInfo} />
                <DocumentDetail DocInfo={documentInfo} /> */}
            <OrdenModal data = {documentInfo}/>
            <ModalProducto url = {url} setUrl={setUrl}/>
            <ModalEtiquetasQr data={documentInfo}/>
            {/* <ModalEtiquetas Etiquetas={documentInfo}/> */}
            <div className="d-flex justify-content-evenly contenedor">
                <div className="col-2">
                   { filter && <GlobalFilter filter = {globalFilter} setFilter = {setGlobalFilter}/>}
                </div>
                <div className="col-2">
                    {filterState && <StateFilter filter = {globalFilter} setFilter = {setGlobalFilter}/>}
                </div>
                <div className="col-2">   
                    {statefilterrepuestos && <StateFilterRepuestos filter={globalFilter} setFilter={setGlobalFilter} />} 
                </div>
                {/* <div className="col-2">
                    {multifilter && <MultiGlobalFilter filters = {globalFilter} setFilters = {setGlobalFilter}/>} 
                </div> */}
                <div className="col-2 d-flex justify-content-center">
                   { exporttable && <ExportTable tablereference={tableRef}/>}
                </div>
            </div>
            <br/>
            <div className = "shadow-lg p-3 mb-5 bg-white rounded" style={{ overflowX: "auto", overflowY: "auto", height: '85rem'}}>
            <div  className='columfiltro'>
                {allColumns.map(column => (
                <div key={column.id} style={{gap:"5px"}}>
                    <label className='labelFiltro'  >
                    <input type='checkbox' {...column.getToggleHiddenProps()} />{' '}
                    <p>{column.Header}</p>
                    </label>
                </div>
                ))}
                <br />
            </div>
            <table className="table table-hover table-sm table-bordered table-responsive-sm align-middle" 
                    {...getTableProps()} ref={tableRef}>
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) =>(
                        <tr {...headerGroup.getHeaderGroupProps()} style={{position:"sticky", top:"0", zIndex:"1"}}>
                            {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        color: 'black',
                                        height: "30px",
                                        textAlign:'center',
                                        backgroundColor:"#87b8ea"}}>
                                       
                                    {column.render('Header')}
                                    <span>{column.isSorted? column.isSortedDesc? '▼': '▲':''}</span></th>
                                ))}
                        </tr>    
                        
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row,rowIndex) => {
                        prepareRow(row);

                        
                return ( 
                    
                    // <React.Fragment key={rowIndex}>
                            <tr {... row.getRowProps()} onInput={() => {setSelecteddata(Object.values(selectedFlatRows.map((row) => row.original)))}} style={{textAlign:"center"}}> 
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} className = 'text-truncate' style={{height: "50px", overflow: 'visible' }}>{cell.render('Cell')}</td>
                                    ;
                                    })}
                                
                            </tr>
                    //         <tr>    
                                    
                    //         </tr> 
                    // </React.Fragment> 
                              
                        );
                    })}
                </tbody>
            </table>
            
            </div>
            {/* Control de paginacion */}
            <div className="col text-center">
                {/* Atras */} 
                <button className="btn btn-outline-dark mx-1" 
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={left} width="40rem" height="40rem"/>
                </button>
                    {/* texto Page 1 of 1 ... */}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {controlledPageCount}
                        </strong>{' '}
                    </span>
                {/* Adelante */}   
                <button className="btn btn-outline-dark" 
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={next} width="40rem" height="40rem"/>
                </button>
                
            </div>
            <br/>
            {/* vertabla? <div id='btnLoading' className="spinner-border spinner-border-sm hideLoad" role="status" />  */}
        </div>
    )
}